import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import DateField from 'ui/components/DateField/DateField';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import HTMLEditorField from 'ui/components/HTMLEditorField';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { createWebAnnouncementSchema } from 'utils/schemas/webAnnouncementSchema';
import { DecentralizedRouteProps } from 'utils/types/common';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import WebAnnouncementsAPI from '../../api/WebAnnouncementsAPI';
import CustomerTypePicker from '../../components/CustomerTypePicker/CustomerTypePicker';
import ProductPicker from '../../components/ProductPicker/ProductPicker';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const options = await WebAnnouncementsAPI.getAnnouncementOptions();

	const url = new URL(request.url);
	const searchParams = new URLSearchParams(url.search);
	const templateId = searchParams.get('templateId');

	const templateWebAnnouncement = templateId
		? await WebAnnouncementsAPI.getAnnouncement(templateId)
		: null;

	return {
		templateWebAnnouncement,
		productOptions: options.productOptions,
		customerTypeOptions: options.customerTypeOptions,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createUserModel = createWebAnnouncementSchema.parse(
			formDataAsObject(formData)
		);

		const data =
			await WebAnnouncementsAPI.createWebAnnouncement(createUserModel);

		if ('announcementId' in data) {
			createToast(ToastType.SUCCESS, 'Web announcement created successfully');
			return redirect(`/web-announcements/${data.announcementId}`);
		}

		return data;
	}

	return null;
};

export function CreateWebAnnouncement() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createWebAnnouncementSchema}>
				<Grid>
					<PageHeader
						title={
							data.templateWebAnnouncement ? (
								<>Duplicate: {data.templateWebAnnouncement.title}</>
							) : (
								'Create Web Announcement'
							)
						}
					>
						<Button variant="secondary" to={`/web-announcements`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Web Announcement"
							type="submit"
							icon={Check}
						>
							Create
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Title"
									name="title"
									isRequired={true}
									defaultValue={data.templateWebAnnouncement?.title}
								/>
								<HTMLEditorField
									label="Body"
									name="body"
									isRequired={true}
									defaultValue={data.templateWebAnnouncement?.body}
								/>
							</Grid>
							<Grid>
								<DateField
									label="Release Date"
									name="releaseDate"
									isRequired={true}
									initialValue={data.templateWebAnnouncement?.releaseDate}
									timezone="utc"
								/>
								<DateField
									label="Expiry Date"
									name="expiryDate"
									isRequired={true}
									initialValue={data.templateWebAnnouncement?.expiryDate}
									timezone="utc"
								/>
								<ProductPicker
									label="Product"
									name="products"
									options={data.productOptions}
									initialValues={data.templateWebAnnouncement?.products}
								/>
								<CustomerTypePicker
									label="Customer Type"
									name="customerTypes"
									options={data.customerTypeOptions}
									initialValues={data.templateWebAnnouncement?.customerTypes}
								/>
								{data.templateWebAnnouncement && (
									<CheckboxField
										name="isArchived"
										label="Hide Announcement"
										value="true"
										defaultChecked={data.templateWebAnnouncement?.isArchived}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_WEB_ANNOUNCEMENT_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <CreateWebAnnouncement />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
