import { FileSpreadsheet, Search } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useActionData,
	useLoaderData,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import Flex from 'ui/components/Flex';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps, LabeledValue } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	RunMonthlyReportsRequest,
} from '../../api/SubscriptionAPI';
import CustomerPicker from '../../components/CustomerPicker';
import MonthlyReportsModal from '../../components/MonthlyReportsModal';
import { DropdownField } from '../../forms/DropdownField';
import * as subscriptionColumns from '../../util/columns/monthlyReportsColumns';

type ActionData = Awaited<ReturnType<typeof action>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const year = document.getElementsByName(
		'reportDate.year'
	)[0] as HTMLInputElement;

	const month = document.getElementsByName(
		'reportDate.month'
	)[0] as HTMLInputElement;

	const url = new URL(request.url);

	const searchParams = url.searchParams;
	if (year && month) {
		searchParams.set('reportDate.year', year.value);
		searchParams.set('reportDate.month', month.value);
	}

	await requireAuthentication(request);
	return SubscriptionAPI.getMonthlyReports(searchParams);
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	const payload: RunMonthlyReportsRequest = {
		'ReportDate.Year': parseInt(formData.get('reportDate.year') as string),
		'ReportDate.Month': parseInt(formData.get('reportDate.month') as string),
		productTypes: formData.getAll('productTypes') as string[],
		customerIds: formData.getAll('customerIds') as string[],
		reportType: formData.get('reportType') as string,
		runOnNewDataSchedule: formData.has('runOnNewDataSchedule'),
	};

	const toastId = createToast(ToastType.LOADING, 'Queuing reports...');

	const response = await SubscriptionAPI.runMonthlyReports(payload);

	if (response.reportBatchId === null) {
		toast.error(`${response.message}`, {
			id: toastId,
		});

		return null;
	} else {
		toast.success('Reports queued successfully', {
			id: toastId,
		});

		return { batchId: response.reportBatchId };
	}
};

export function MonthlyReports() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [isRunReportModalOpen, setRunReportModalOpen] = useState(false);
	const navigate = useNavigate();

	const [, setSearchParams] = useSearchParams();

	const columns = [
		subscriptionColumns.deliveryStatusColumn,
		subscriptionColumns.nameColumn,
		subscriptionColumns.customerColumn,
		subscriptionColumns.customerStatusColumn,
		subscriptionColumns.productNameColumn,
		subscriptionColumns.startPeriodColumn,
		subscriptionColumns.endPeriodColumn,
		subscriptionColumns.licensesColumn,
		subscriptionColumns.originsColumn,
		subscriptionColumns.destinationsColumn,
		subscriptionColumns.usersColumn,
	];

	const [reportYearAndMonth, setReportYearAndMonth] = useState<YearAndMonth>({
		year: data.reportDateYear,
		month: data.reportDateMonth,
	});

	const filterFormRef = useRef<HTMLFormElement>(null);

	// Close the modal once actionData changes (and modal is open)
	const actionData = useActionData() as ActionData;
	useEffect(() => {
		if (actionData && isRunReportModalOpen) {
			setRunReportModalOpen(false);

			navigate(`/reports/monitor?batchIds=${actionData.batchId}`);
		}
	}, [actionData]);

	const handleReportTypeChange = (option: LabeledValue | null) => {
		const newSearchParams = new URLSearchParams();
		if (option) {
			newSearchParams.set('reportType', option.value);
		}
		setSearchParams(newSearchParams);

		filterFormRef.current?.reset();
	};

	const pageTitle =
		data.reportType.value === 'awb'
			? 'Monthly Demand Report Distribution'
			: 'Monthly Capacity Report Distribution';

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title={pageTitle}>
					<ValidatedForm method="post">
						<input
							type="hidden"
							id="reportType"
							name="reportType"
							value={data.reportType.value}
						/>
						<input
							type="hidden"
							id="productTypes"
							name="productTypes"
							value={data.productTypes}
						/>
						<input
							type="hidden"
							id="customerIds"
							name="customerIds"
							value={data.customerIds}
						/>
						<Flex gap={12}>
							<DropdownField
								name="reportType"
								options={data.reportTypeOptions}
								key="value"
								contentSource={(data) => data.label}
								initialValue={{
									value: data.reportType.value,
									label: data.reportType.label,
								}}
								identifierKey="value"
								isClearable={false}
								onOptionSelected={handleReportTypeChange}
							/>

							<YearMonthPicker
								name="reportDate"
								value={reportYearAndMonth}
								onChange={(value) =>
									value ? setReportYearAndMonth(value) : null
								}
							/>

							<Button
								variant="primary"
								icon={FileSpreadsheet}
								isDisabled={data.totalEnabledReports === 0}
								onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
									setRunReportModalOpen(true);
									e.preventDefault();
								}}
							>
								Run reports
							</Button>
						</Flex>
					</ValidatedForm>
				</PageHeader>

				<div className="statistics">
					<div className="statistics__item">
						<b>{data.totalEnabledSubscriptions}</b>
						&nbsp;
						<span>
							Enabled Subscriptions (<b>{data.totalEnabledReports}</b> Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalDisabledSubscriptions}</b>&nbsp;
						<span>
							Disabled Subscriptions (<b>{data.totalDisabledReports}</b>{' '}
							Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalSubscriptions}</b>
						&nbsp;
						<span>
							Total Subscriptions (<b>{data.totalReports}</b> Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalInactiveCustomers}</b>&nbsp;
						<span>Inactive Customers</span>
					</div>
				</div>

				{isRunReportModalOpen && (
					<MonthlyReportsModal
						reportType={data.reportType}
						totalReports={data.totalEnabledReports}
						productTypes={data.productTypes}
						customerIds={data.customerIds}
						reportDateYear={reportYearAndMonth.year}
						reportDateMonth={reportYearAndMonth.month}
						isOpen={isRunReportModalOpen}
						onClose={() => setRunReportModalOpen(false)}
					/>
				)}

				<Table
					identifierKey="id"
					columns={columns}
					data={data.subscriptions.items}
				/>

				{data.subscriptions.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.subscriptions.page}
						pageParameterName="page"
						pageSize={data.subscriptions.pageSize}
						itemCount={data.subscriptions.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Subscription Filter">
				<ValidatedForm ref={filterFormRef} method="get" resetOnNavigation>
					<input
						type="hidden"
						name="reportType"
						value={data.reportType.value}
					/>
					<MultiSelectField
						label="Product"
						name="productTypes"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="productType"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>

					<MultiSelectField
						label="Delivery Status"
						name="subscriptionDeliveryStatus"
						options={data.subscriptionDeliveryStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<MultiSelectField
						label="Customer Status"
						name="customerStatus"
						options={data.customerStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const SUBSCRIPTIONS_MONTHLY_REPORTS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <MonthlyReports />,
};
