import { Option } from './common';

export type PickByType<T, Type> = {
	[P in keyof T as T[P] extends Type ? P : never]: T[P];
};

export type StringKeys<T> = keyof PickByType<T, string> & string;
export type IdentifierKeys<T> = keyof PickByType<T, string | number> &
	(string | number);

export type ReplaceKeyTypes<
	T extends Record<string, unknown>,
	V extends { [K in keyof T]?: unknown },
> = {
	// TS doesn't like the {} here, but changing it to a Record<> breaks the type:
	// eslint-disable-next-line
	[key in keyof T]: V[key] extends {} ? V[key] : T[key];
};

export type YearAndMonth = {
	year: number;
	month: number;
	week?: Option<number>;
	yearMonth?: Option<number>;
	yearMonthWeek?: Option<number>;
	yearWeek?: Option<number>;
};

export type YearMonthAndWeek = {
	year: number;
	month: number;
	week: number;
	yearMonthWeek?: number;
};

// The server provides an additional yearMonth combination of year + month, which is not necessary in requests to the server
export type YearAndMonthApi = YearAndMonth & {
	yearMonth: number;
};

export function yearMonthToDate(yearMonth: YearAndMonth | null | undefined) {
	if (!yearMonth) return null;

	return new Date(yearMonth.year, yearMonth.month - 1, 1);
}

export function dateToYearMonth(
	date: Date | null | undefined
): YearAndMonth | null {
	if (!date) return null;

	return {
		month: date.getMonth() + 1,
		year: date.getFullYear(),
	};
}

export type ISODateString =
	`${number}-${number}-${number}T${number}:${number}:${number}${string}`;
