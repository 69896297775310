import {
	LayoutRouteProps,
	Link,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import requireAuthentication from 'utils/helpers/requireAuthentication';

type LoaderData = Awaited<ReturnType<typeof loader>>;

function reportSummary(
	name: string,
	queued: number,
	completed: number,
	errored: number
) {
	return {
		name: name,
		total: queued + completed + errored,
		queued: queued,
		completed: completed,
		errored: errored,
	};
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	let searchParams = new URL(request.url).searchParams;
	const data = {
		cassSummary: {
			loaded: 30,
			missing: 5,
			expected: 35,
		},
		cddSummary: {
			loaded: 9,
			missing: 2,
			expected: 11,
		},
		previousPublish: {
			month: 'October 2022',
			publishedOn: '25 Oct 2022 15:00 UTC',
		},
		reportQueue: {
			summary: searchParams.get('published')
				? [
						reportSummary('CASS BIP', 0, 71, 0),
						reportSummary('CASS MF', 40, 48, 1),
						reportSummary('Top Report', 5, 143, 2),
						reportSummary('Market Share', 0, 135, 2),
						reportSummary('Ranking Report', 10, 50, 0),
						reportSummary('Standard Report', 95, 10, 2),
					]
				: [],
			length: 1200,
		},
		systemStatus: 'Idle',
	};

	return data;
};

export function PublishDashboard() {
	const data = useLoaderData() as LoaderData;

	return (
		<div className="details-view-wrapper">
			<PageHeader title="Publication Dashboard" />

			<KeyValuePairs
				entries={[
					{ key: 'System Status', value: data.systemStatus },
					{
						key: 'Last Publish',
						value: `${data.previousPublish.publishedOn} for (${data.previousPublish.month})`,
					},
				]}
			/>

			<Grid columns={2}>
				<Grid>
					<h2>Data Published</h2>

					<table>
						<thead>
							<tr>
								<th>Metric</th>
								<th>Last Month</th>
								<th>This Month</th>
								<th>Difference</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>AWBs</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Airlines</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Trade Lanes</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</Grid>
				<Grid>
					<h2>Reports Generated (Only from publish)</h2>

					<p>
						<Link to="/reports/generator">Generate reports</Link>
					</p>

					<table className="data-table">
						<thead>
							<tr>
								<th>Report</th>
								<th>Queued</th>
								<th>Completed</th>
								<th>Errored</th>
								<th>Success</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{data.reportQueue.summary.map((report) => (
								<tr key={report.name}>
									<td>{report.name}</td>
									<td>{report.queued}</td>
									<td>{report.completed}</td>
									<td>{report.errored}</td>
									<td>{report.total}</td>
									<td>
										{Math.round((100 / report.total) * report.completed)}%
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Grid>
			</Grid>
		</div>
	);
}

export const POST_PUBLISH_DASHBOARD_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <PublishDashboard />,
	handle: {
		crumb: () => 'Post-Publish Dashboard',
	},
};
