import classNames from 'classnames';
import { RotateCcw } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import WeightBreakGroup from './WeightBreakGroup';
import WeightBreakItem from './WeightBreakItem';
import { WeightBreak } from './index';

export const DEFAULT_WEIGHT_BREAKS_CASS: WeightBreak[] = [
	{ index: 0, from: 0, to: 4.99 },
	{ index: 1, from: 5, to: 9.99 },
	{ index: 2, from: 10, to: 19.99 },
	{ index: 3, from: 20, to: 29.99 },
	{ index: 4, from: 30, to: 44.99 },
	{ index: 5, from: 45, to: 59.99 },
	{ index: 6, from: 60, to: 79.99 },
	{ index: 7, from: 80, to: 99.99 },
	{ index: 8, from: 100, to: 149.99 },
	{ index: 9, from: 150, to: 199.99 },
	{ index: 10, from: 200, to: 249.99 },
	{ index: 11, from: 250, to: 299.99 },
	{ index: 12, from: 300, to: 399.99 },
	{ index: 13, from: 400, to: 499.99 },
	{ index: 14, from: 500, to: 749.99 },
	{ index: 15, from: 750, to: 999.99 },
	{ index: 16, from: 1000, to: 1499.99 },
	{ index: 17, from: 1500, to: 1999.99 },
	{ index: 18, from: 2000, to: 2999.99 },
	{ index: 19, from: 3000, to: 4999.99 },
	{ index: 20, from: 5000, to: 9999.99 },
	{ index: 21, from: 10000, to: Infinity },
];

export const DEFAULT_WEIGHT_BREAKS_CDD: WeightBreak[] = [
	{ index: 0, from: 0, to: 44.99 },
	{ index: 1, from: 45, to: 99.99 },
	{ index: 2, from: 100, to: 299.99 },
	{ index: 3, from: 300, to: 499.99 },
	{ index: 4, from: 500, to: 999.99 },
	{ index: 5, from: 1000, to: 4999.99 },
	{ index: 6, from: 5000, to: Infinity },
];

export type WeightBreaksProps = {
	name?: string;
	weightBreaks?: WeightBreak[];
	initialValue?: number[];
	value?: number[];
	onChange?: (value: number[]) => void;
	isDisabled?: boolean;
};

const WeightBreaks = ({
	name,
	weightBreaks = DEFAULT_WEIGHT_BREAKS_CASS,
	initialValue,
	isDisabled,
	value: propsValue,
	onChange: propsOnChange,
}: WeightBreaksProps) => {
	const [stateValue, setStateValue] = useState<number[]>(initialValue ?? []);

	const value = propsValue ?? stateValue;
	const setValue = propsOnChange ?? setStateValue;

	const links = useMemo(() => {
		if (!value || value.length === 0) {
			return new Array(weightBreaks.length - 1).fill(false);
		}

		const links = value
			.slice(1)
			.reduce<
				boolean[]
			>((acc, cur, curIdx) => [...acc, value[curIdx] === cur], []);

		return links;
	}, [value, weightBreaks]);

	const setLinks = useCallback(
		(newLinks: boolean[]) => {
			const newValue = newLinks.reduce<number[]>(
				(acc, cur, curIdx) => [...acc, cur ? acc[curIdx] : acc[curIdx] + 1],
				[1]
			);

			setValue(newValue);
		},
		[setValue, weightBreaks]
	);

	const groupedItems = weightBreaks.reduce<WeightBreak[][]>(
		(acc, cur, curIdx) => {
			if (curIdx === 0) {
				return [[cur]];
			}

			// If the previous and current item are linked, add the current item to the last array,
			// otherwise create a new array.
			if (links[curIdx - 1]) {
				acc[acc.length - 1].push(cur);
			} else {
				acc.push([cur]);
			}

			return acc;
		},
		[]
	);

	const [selectionStartIndex, setSelectionStartIndex] = useState<number | null>(
		null
	);

	const [selectionEndIndex, setSelectionEndIndex] = useState<number | null>(
		null
	);

	const clearGroup = (groupIndex: number) => {
		const newLinks = links.map((link, linkIndex) => {
			const group = groupedItems[groupIndex];
			const groupStartIndex = group[0].index;
			const groupEndIndex = group[group.length - 1].index;

			// If the link is within the group, unlink
			if (linkIndex >= groupStartIndex && linkIndex <= groupEndIndex) {
				return false;
			}

			return link;
		});

		setLinks(newLinks);
	};

	const handleSelection = (index: number) => {
		if (selectionStartIndex === null) {
			setSelectionStartIndex(index);
		} else {
			const newLinks = links.map((link, linkIdx) => {
				if (
					linkIdx >= Math.min(index, selectionStartIndex) &&
					linkIdx < Math.max(index, selectionStartIndex)
				) {
					return true;
				}

				return link;
			});

			setLinks(newLinks);
			setSelectionStartIndex(null);
			setSelectionEndIndex(null);
		}
	};

	const isInSelection = (index: number) => {
		if (selectionStartIndex === null || selectionEndIndex === null) {
			return false;
		}

		const start = Math.min(selectionStartIndex, selectionEndIndex);
		const end = Math.max(selectionStartIndex, selectionEndIndex);

		return index >= start && index <= end;
	};

	const resetLinks = () => {
		setLinks(new Array(weightBreaks.length - 1).fill(false));
	};

	return (
		<div
			className={classNames(
				'weightbreaks',
				isDisabled && 'weightbreaks--disabled'
			)}
		>
			<div className="weightbreaks__header">
				<span>Min</span>
				<span
					className={classNames(
						'weightbreaks__reset',
						groupedItems.length < weightBreaks.length &&
							'weightbreaks__reset--enabled'
					)}
					role="button"
					onClick={resetLinks}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							resetLinks();
						}
					}}
					tabIndex={0}
				>
					<RotateCcw size={14} />
					Reset
				</span>
				<span>Max</span>
			</div>
			<div className="weightbreaks__list">
				{groupedItems.map((group, groupIdx) => {
					if (group.length === 1) {
						return (
							<WeightBreakItem
								key={`weight-break-item-${groupIdx}`}
								from={group[0].from}
								to={group[0].to}
								role="button"
								tabIndex={0}
								onMouseEnter={() => setSelectionEndIndex(group[0].index)}
								onFocus={() => setSelectionEndIndex(group[0].index)}
								isSelected={isInSelection(group[0].index)}
								onClick={() => handleSelection(group[0].index)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										handleSelection(group[0].index);
									}
								}}
							/>
						);
					} else {
						return (
							<WeightBreakGroup
								key={`weight-break-group-${groupIdx}`}
								items={group}
								onClear={() => {
									clearGroup(groupIdx);
								}}
							/>
						);
					}
				})}
			</div>
			{name &&
				groupedItems.length < weightBreaks.length &&
				weightBreaks.map((weightBreak, weightBreakIdx) => (
					<input
						key={`weight-break-input-${weightBreakIdx}`}
						type="hidden"
						name={`${name}.${weightBreakIdx}`}
						value={
							groupedItems.findIndex((group) => group.includes(weightBreak)) + 1
						}
					/>
				))}
		</div>
	);
};

export default WeightBreaks;
