import { useMemo, useState } from 'react';
import Alert from 'ui/components/Alert';
import CASSAreaPicker from 'ui/components/CASSAreaPicker';
import CheckboxField from 'ui/components/CheckboxField';
import Grid from 'ui/components/Grid';
import ValidatedForm from 'ui/components/ValidatedForm';
import { CASSAreaOption } from 'utils/api/CASSAreasAPI';
import { YearAndMonthApi } from 'utils/types/helpers';
import { HotfileMonthAreas } from '../../api/DataManagementAPI';
import { YearMonthFragment } from '../YearMonth/YearMonthFragment';
import HotfileAreaButton from './HotfileAreaButton';
import HotfileMonth from './HotfileMonth';

export type HotfileMonitorProps = {
	months: HotfileMonthAreas[];
	selectedMonth: YearAndMonthApi;
};

const HotfileMonitor = ({ months, selectedMonth }: HotfileMonitorProps) => {
	const [highlightedAreas, setHighlightedAreas] = useState<string[]>([]);
	const [selectedAreas, setSelectedAreas] = useState<CASSAreaOption[]>([]);

	const [showOnlyMissingBillingFiles, setShowOnlyMissingBillingFiles] =
		useState(false);
	const [showOnlyMissingCorrectionFiles, setShowOnlyMissingCorrectionFiles] =
		useState(false);

	const activeMonth = months.find(
		(month) =>
			month.year === selectedMonth.year && month.month === selectedMonth.month
	);

	const allAreas = useMemo(() => {
		const allCassAreas = months.reduce<Array<string>>((acc, cur) => {
			acc.push(...Object.keys(cur.areas));
			return acc;
		}, []);

		const cassAreaSet = new Set(allCassAreas);
		return [...cassAreaSet].sort((a, b) => a.localeCompare(b));
	}, [months]);

	const visibleAreas = useMemo(() => {
		let visibleAreas = allAreas;

		if (selectedAreas.length > 0) {
			visibleAreas = visibleAreas.filter((area) =>
				selectedAreas.some((selectedArea) => selectedArea.code === area)
			);
		}

		// Missing billing files
		if (showOnlyMissingBillingFiles && activeMonth) {
			visibleAreas = visibleAreas.filter((area) =>
				activeMonth.areas[area].some(
					(file) => file.type === 'billing' && !file.exists
				)
			);
		}

		// Missing correction files
		if (showOnlyMissingCorrectionFiles && activeMonth) {
			visibleAreas = visibleAreas.filter((area) =>
				activeMonth.areas[area].some(
					(file) => file.type === 'correction' && !file.exists
				)
			);
		}

		return visibleAreas;
	}, [
		allAreas,
		selectedAreas,
		showOnlyMissingBillingFiles,
		showOnlyMissingCorrectionFiles,
		activeMonth,
	]);

	// Current, next and previous months are always shown
	const recentMonths = useMemo(() => months.slice(-3), [months]);
	const pastMonths = useMemo(() => months.slice(0, -3), [months]);

	// Count the number of files within existingMonth that are a billing type and exist
	const missingBillingFilesCount = activeMonth
		? Object.values(activeMonth.areas).reduce(
				(acc, cur) =>
					acc + cur.filter((f) => f.type === 'billing' && !f.exists).length,
				0
			)
		: 0;

	const missingCorrectionFilesCount = activeMonth
		? Object.values(activeMonth.areas).reduce(
				(acc, cur) =>
					acc + cur.filter((f) => f.type === 'correction' && !f.exists).length,
				0
			)
		: 0;

	const toggleHighlightedArea = (area: string) => {
		if (highlightedAreas.includes(area)) {
			setHighlightedAreas(highlightedAreas.filter((a) => a !== area));
		} else {
			setHighlightedAreas([...highlightedAreas, area]);
		}
	};

	const handleSelectedCASSAreasChange = (areas: CASSAreaOption[]) => {
		setSelectedAreas(areas);
		setHighlightedAreas([]);
	};

	return (
		<ValidatedForm>
			<div className="hotfilemonitor">
				<div className="hotfilemonitor__settings">
					<Grid columns="2fr 3fr">
						<CASSAreaPicker
							name="cassAreas"
							isMulti
							selectedCASSAreas={selectedAreas}
							onSelectedCASSAreasChange={handleSelectedCASSAreasChange}
							placeholder="Filter by CASS areas..."
						/>
					</Grid>

					<div className="hotfilemonitor__filters">
						<div className="hotfilemonitor__filter">
							<CheckboxField
								name="showOnlyMissingBillingFiles"
								label={`Show only missing billing files (${missingBillingFilesCount})`}
								onChange={(e) =>
									setShowOnlyMissingBillingFiles(e.target.checked)
								}
							/>
						</div>
						<div className="hotfilemonitor__filter">
							<CheckboxField
								name="showOnlyMissingCorrectionFiles"
								label={`Show only missing correction files (${missingCorrectionFilesCount})`}
								onChange={(e) =>
									setShowOnlyMissingCorrectionFiles(e.target.checked)
								}
							/>
						</div>
					</div>
				</div>
				{!activeMonth && (
					<Alert title="Data not available">
						Data is not available for{' '}
						<YearMonthFragment yearMonth={selectedMonth.yearMonth} /> yet.
					</Alert>
				)}
				<div className="hotfilemonitor__content">
					<div className="hotfilemonitor__areas">
						<div>&nbsp;</div>
						{visibleAreas.map((cassArea) => (
							<HotfileAreaButton
								key={cassArea}
								isSelected={highlightedAreas.includes(cassArea)}
								onToggleHighlighted={() => {
									toggleHighlightedArea(cassArea);
								}}
							>
								{cassArea}
							</HotfileAreaButton>
						))}
					</div>
					<div className="hotfilemonitor__section hotfilemonitor__section--archive">
						{pastMonths.map((month) => (
							<HotfileMonth
								key={`${month.year}-${month.month}`}
								month={month}
								highlightedAreas={highlightedAreas}
								allAreas={allAreas}
								visibleAreas={visibleAreas}
								compact
							/>
						))}
					</div>
					<div className="hotfilemonitor__section hotfilemonitor__section--sticky">
						{recentMonths.map((month) => (
							<HotfileMonth
								key={`${month.year}-${month.month}`}
								month={month}
								allAreas={allAreas}
								highlightedAreas={highlightedAreas}
								visibleAreas={visibleAreas}
								isHighlighted={
									month.year === activeMonth?.year &&
									month.month === activeMonth?.month
								}
							/>
						))}
					</div>
				</div>
				<div className="hotfilemonitor__footer">
					<div className="hotfilemonitor__highlights">
						{highlightedAreas.length} / {visibleAreas.length} highlighted
						{highlightedAreas.length > 0 && (
							<>
								{' '}
								(
								<span
									className="hotfilemonitor__clear-highlights"
									onClick={() => setHighlightedAreas([])}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											setHighlightedAreas([]);
										}
									}}
									role="button"
									tabIndex={0}
								>
									Clear
								</span>
								)
							</>
						)}
					</div>
				</div>
			</div>
		</ValidatedForm>
	);
};

export default HotfileMonitor;
