import { ClipboardSignature } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { PublishMonth } from '../../api/DataManagementAPI';
import { CapacityMonthlyPublishAction } from './capacity-summary-dashboard';

type PublishCapacityMonthlyDataModalProps = {
	publishMonth: PublishMonth;
} & Omit<ModalProps, 'title'>;

const PublishCapacityMonthlyDataModal = ({
	publishMonth,
	...modalProps
}: PublishCapacityMonthlyDataModalProps) => {
	return (
		<ConfirmationModal
			fieldName="month"
			title="Publish Capacity Monthly Data"
			submitIcon={ClipboardSignature}
			submitText="Publish Capacity Monthly Data"
			confirmationText={publishMonth.description}
			formMethod="post"
			formAction="/publish/capacity-summary"
			{...modalProps}
		>
			<input
				type="hidden"
				name="action"
				value={CapacityMonthlyPublishAction.Publish}
			/>
			<input type="hidden" name="yearMonth" value={publishMonth.yearMonth} />

			<p>
				Are you sure you wish to publish the data for{' '}
				<b>{publishMonth.description}</b>?
			</p>
		</ConfirmationModal>
	);
};

export default PublishCapacityMonthlyDataModal;
