/// <reference path="../../../../types/table.d.ts" />

import {
	Column,
	ColumnDef,
	ColumnSizingState,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Header,
	RowSelectionState,
	TableOptions,
	useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import {
	ChevronDown,
	ChevronUp,
	Loader2,
	PackageOpen,
	TimerReset,
} from 'lucide-react';
import {
	CSSProperties,
	memo,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { IdentifierKeys } from 'utils/types/helpers';
import Tooltip from '../Tooltip';
import TableMessage, { TableMessageAction } from './TableMessage';
import {
	TableSortingStrategy,
	useSearchParamSortStrategy,
} from './tableSorting';

export type TableProps<T> = {
	identifierKey: IdentifierKeys<T>;
	data: T[];
	columns: (ColumnDef<T, any> | false | null)[];
	initialSelection?: string[];

	emptyText?: string;
	emptyHint?: string;
	formId?: string;

	isLoading?: boolean;
	loadingText?: string;
	loadingHint?: string;
	loadingActions?: TableMessageAction[];

	className?: string;

	onRowSelectionChange?: (rowSelection: RowSelectionState) => void;
	sortingStrategy?: TableSortingStrategy;

	tableOptions?: Partial<TableOptions<T>>;
	showTotalLabels?: boolean;

	hasWarning?: boolean;
	warningText?: string;
	warningHint?: string;
	warningActions?: TableMessageAction[];

	highlightSelectedRows?: boolean;
};

const Table = <T extends {}>({
	identifierKey,
	data,
	columns,
	initialSelection,
	emptyText = 'No entries found.',
	emptyHint,
	isLoading,
	formId,
	loadingText = 'Loading data...',
	loadingHint,
	loadingActions,
	className,
	onRowSelectionChange,
	sortingStrategy: propsSortingStrategy,
	tableOptions,
	showTotalLabels,

	hasWarning = false,
	warningText,
	warningHint,
	warningActions,

	highlightSelectedRows,
}: TableProps<T>) => {
	const searchParamSortingStrategy = useSearchParamSortStrategy();
	const sortingStrategy = propsSortingStrategy ?? searchParamSortingStrategy;
	const tableRef = useRef<HTMLTableElement>(null);

	const [expanded, setExpanded] = useState<ExpandedState>({});

	const activeColumns = useMemo(() => {
		return columns.filter((c) => !!c) as ColumnDef<T, any>[];
	}, [columns]);

	const initialRowSelection = useMemo(() => {
		return (
			initialSelection?.reduce<RowSelectionState>((acc, cur) => {
				acc[cur] = true;
				return acc;
			}, {}) || ({} as RowSelectionState)
		);
	}, [initialSelection]);

	const initialSortingState = useMemo(
		() => sortingStrategy.getSortingState(activeColumns),
		[sortingStrategy, activeColumns]
	);

	const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});

	const getPinningStyles = (column: Column<T>): CSSProperties | undefined => {
		const isPinned = column.getIsPinned();

		if (!isPinned) return;

		const isLastLeftPinnedColumn =
			isPinned === 'left' && column.getIsLastColumn('left');
		const isFirstRightPinnedColumn =
			isPinned === 'right' && column.getIsFirstColumn('right');

		return {
			boxShadow: isLastLeftPinnedColumn
				? '-4px 0 4px -4px gray inset'
				: isFirstRightPinnedColumn
					? '4px 0 4px -4px gray inset'
					: undefined,
			left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
			right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
			position: 'sticky',
			minWidth: column.getSize(),
			zIndex: 1,
		};
	};

	const { state: tableOptionsState, ...tableOptionsRest } = tableOptions ?? {};

	const table = useReactTable({
		data,
		columns: activeColumns,
		manualSorting: true,
		manualPagination: true,
		sortDescFirst: false,
		defaultColumn: {
			enableSorting: false,
		},
		state: {
			expanded,
			sorting: initialSortingState,
			columnPinning: {
				left: activeColumns
					.filter((c) => c.meta?.pinned === 'left' && c.id)
					.map((c) => c.id!),
				right: activeColumns
					.filter((c) => c.meta?.pinned === 'right' && c.id)
					.map((c) => c.id!),
			},
			columnSizing,
			...tableOptionsState,
		},
		onExpandedChange: setExpanded,
		onColumnSizingChange: setColumnSizing,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getRowId: (row) => row[identifierKey] as unknown as string,
		initialState: {
			rowSelection: initialRowSelection,
		},
		columnResizeMode: 'onChange',
		...tableOptionsRest,
	});

	useEffect(() => {
		onRowSelectionChange?.(table.getState().rowSelection);
	}, [table.getState().rowSelection, onRowSelectionChange]);

	const tableContents = useMemo(() => {
		const rows = table.getRowModel().rows;

		// Move highlighted rows to top
		const rowsWithHighlight = highlightSelectedRows
			? rows.filter((row) => row.getIsSelected())
			: [];
		const rowsWithoutHighlight = highlightSelectedRows
			? rows.filter((row) => !row.getIsSelected())
			: rows;

		const orderedHighlightedRows = [
			...rowsWithHighlight,
			...rowsWithoutHighlight,
		];

		return orderedHighlightedRows.map((row) => (
			<tr
				key={row.id}
				className={classNames(
					'table__row',
					row.depth > 0 && 'table__row--subrow',
					row.getIsSelected() && 'table__row--selected'
				)}
			>
				{row.getVisibleCells().map((cell) => (
					<td
						className={classNames(
							'table__cell',
							row.depth > 0 && 'table__cell--subrow',
							cell.column.columnDef.meta?.contentAlignment &&
								`table__cell--align-${cell.column.columnDef.meta.contentAlignment}`,
							cell.column.columnDef.meta?.className
						)}
						style={{ ...getPinningStyles(cell.column) }}
						key={cell.id}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</td>
				))}
			</tr>
		));
	}, [
		table,

		// Properties affecting the table hook
		activeColumns,
		data,
		initialSortingState,
		setExpanded,
		initialRowSelection,
		tableOptions,
	]);

	const showWarning = hasWarning && warningText;

	const tableBody = useMemo(() => {
		if (isLoading) {
			return (
				<TableMessage
					text={loadingText}
					hint={loadingHint}
					className="table__loader"
					icon={Loader2}
					tableRef={tableRef}
					actions={loadingActions}
				/>
			);
		} else if (table.getRowModel().rows.length === 0) {
			return (
				<TableMessage
					text={emptyText}
					hint={emptyHint}
					icon={PackageOpen}
					tableRef={tableRef}
				/>
			);
		} else {
			return (
				<>
					{showWarning && (
						<TableMessage
							isInline
							text={warningText}
							hint={warningHint}
							icon={TimerReset}
							actions={warningActions ?? []}
							tableRef={tableRef}
						/>
					)}
					{tableContents}
				</>
			);
		}
	}, [
		isLoading,
		loadingText,
		loadingHint,
		emptyText,
		emptyHint,
		table,
		hasWarning,
		warningText,
		warningHint,
		warningActions,

		// Properties affecting the table hook
		activeColumns,
		data,
		initialSortingState,
		setExpanded,
		initialRowSelection,
		tableOptions,
	]);

	const doesColumnHaveFooter = useCallback((columnDef: ColumnDef<T, any>) => {
		if (columnDef.footer !== undefined && columnDef.footer !== null)
			return true;

		if ('columns' in columnDef && columnDef.columns) {
			return columnDef.columns.some(doesColumnHaveFooter);
		}

		return false;
	}, []);

	const hasFooter = activeColumns.some(doesColumnHaveFooter);
	const hasMultiSort = initialSortingState.length > 1;

	const handleTableHeaderClick = (
		e: MouseEvent,
		header: Header<T, unknown>
	) => {
		if (!header.column.getCanSort() || !sortingStrategy) return;

		sortingStrategy.onSort(
			'accessorKey' in header.column.columnDef
				? header.column.columnDef.accessorKey.toString()
				: '',
			header.column.getNextSortingOrder() || null,
			e.shiftKey && header.column.getCanMultiSort()
		);
	};

	return (
		<div
			className={classNames(
				'table',
				highlightSelectedRows && 'table--highlight-selected',
				className
			)}
			ref={tableRef}
		>
			<table className="table__main">
				<thead className="table__header">
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header, headerIndex) => (
								<th
									colSpan={header.colSpan}
									key={header.id}
									className={classNames(
										'table__column',
										header.column.getCanSort() && 'table__column--sortable',
										header.column.columnDef.meta?.headerAlignment &&
											`table__column--align-${header.column.columnDef.meta.headerAlignment}`,
										header.column.columnDef.meta?.shrink &&
											'table__column--shrink',
										header.column.columnDef.meta?.highlightHeader &&
											'table__column--highlight'
									)}
									style={{ ...getPinningStyles(header.column) }}
									onClick={(e) => handleTableHeaderClick(e, header)}
								>
									<Tooltip
										disabled={
											typeof header.column.columnDef.meta?.tooltip !== 'string'
										}
									>
										<Tooltip.Trigger>
											<span>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
											</span>
										</Tooltip.Trigger>
										<Tooltip.Content>
											{header.column.columnDef.meta?.tooltip}
										</Tooltip.Content>
									</Tooltip>

									{hasMultiSort ? (
										header.column.getIsSorted() && (
											<div className="table__sort-pill">
												{header.column.getSortIndex() + 1}
												{header.column.getIsSorted() === 'asc' && (
													<ChevronUp size={14} />
												)}
												{header.column.getIsSorted() === 'desc' && (
													<ChevronDown size={14} />
												)}
											</div>
										)
									) : (
										<>
											{header.column.getIsSorted() === 'asc' && (
												<ChevronUp size={16} />
											)}
											{header.column.getIsSorted() === 'desc' && (
												<ChevronDown size={16} />
											)}
										</>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className="table__body">{tableBody}</tbody>
				{hasFooter && (
					<tfoot className="table__footer">
						{table
							.getFooterGroups()
							.slice(0, 1)
							.map((footerGroup) => (
								<tr key={footerGroup.id}>
									{footerGroup.headers.map((header) => {
										const renderedValue = flexRender(
											header.column.columnDef.footer,
											header.getContext()
										);

										return (
											<th
												className={classNames(
													'table__cell',
													showTotalLabels && 'table__cell--total',
													header.column.columnDef.meta?.contentAlignment &&
														`table__cell--align-${header.column.columnDef.meta.contentAlignment}`
												)}
												key={header.id}
												colSpan={header.colSpan}
											>
												{header.isPlaceholder ? null : (
													<>
														{showTotalLabels && renderedValue ? (
															<div className="table__total">
																<span>Total</span>
																{renderedValue}
															</div>
														) : (
															renderedValue
														)}
													</>
												)}
											</th>
										);
									})}
								</tr>
							))}
					</tfoot>
				)}
			</table>
		</div>
	);
};

export default memo(Table) as typeof Table;
