import React, { useEffect, useState } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	Navigation,
	useActionData,
	useLoaderData,
	useNavigate,
	useNavigation,
} from 'react-router-dom';
import Alert from 'ui/components/Alert';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import RevalidationButton from 'ui/components/RevalidationButton';
import ValidatedForm from 'ui/components/ValidatedForm';
import APIError from 'utils/errors/APIError';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import DataManagementAPI, {
	CommonLambdaResponse,
	MetricEntry,
} from '../../api/DataManagementAPI';
import {
	YearMonthFragment,
	getYearMonthFromQueryStringOrDefault,
} from '../../components/YearMonth/YearMonthFragment';
import { DropdownField } from '../../forms/DropdownField';
import {
	blockingProcessIntent,
	systemOverviewIntent,
} from '../monitor/system-status';
import PublishCapacityMonthlyDataModal from './PublishCapacityMonthlyDataModal';
import Card from 'ui/components/Card';
import Flex from 'ui/components/Flex';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'ui/components/Table';
import ReactDOMServer from 'react-dom/server';
import NumberFragment from 'ui/components/NumberFragment';
import { LoaderData } from 'utils/types/loaderData';

type ActionData = Awaited<ReturnType<typeof action>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const yearMonth = getYearMonthFromQueryStringOrDefault(request);

	return await DataManagementAPI.publishCapacitySummary(yearMonth);
};

export const action = async ({
	request,
}: ActionFunctionArgs): Promise<CommonLambdaResponse | null> => {
	let apiResponse: CommonLambdaResponse | Error | APIError | null = null;
	const formData = await request.formData();

	switch (formData.get('action')) {
		case CapacityMonthlyPublishAction.CapacityCloseOut:
			apiResponse = await DataManagementAPI.startProcessCapacityData();
			break;
		case CapacityMonthlyPublishAction.Publish: {
			const yearMonth = Number(formData.get('yearMonth'));
			apiResponse =
				await DataManagementAPI.startPublishCapacityMonthlyData(yearMonth);
			break;
		}
	}

	if (!apiResponse) return null;

	// Bring any message into view
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});

	if ('fieldErrors' in apiResponse) {
		return {
			errorCode: 'field-errors',
			successCode: null,
			message: 'There were errors with the data you submitted.',
			fieldErrors: apiResponse.fieldErrors,
		} as CommonLambdaResponse;
	}

	if (apiResponse instanceof Error) {
		return null;
	}

	return apiResponse;
};

export enum CapacityMonthlyPublishAction {
	CapacityCloseOut = 'capacity-close-out',
	Publish = 'publish-capacty-monthly',
}

export function isSubmittingSystemAction(
	action: CapacityMonthlyPublishAction,
	navigation: Navigation
) {
	return (
		navigation.state !== 'idle' &&
		navigation.formAction === '/publish/capacity-monthly' &&
		navigation.formData?.get('action') === action
	);
}

function systemButtonState(
	action: CapacityMonthlyPublishAction,
	navigation: Navigation,
	systemBusy: boolean
) {
	const isDisabled =
		systemBusy ||
		(navigation.state !== 'idle' &&
			navigation.formAction === '/publish/monthly' &&
			navigation.formData?.get('action') !== action);

	return {
		isDisabled,
		isLoading: isSubmittingSystemAction(action, navigation),
	};
}

const comparisonColumnHelper = createColumnHelper<MetricEntry>();

export function CapacitySummaryDashboard() {
	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData;
	const navigation = useNavigation();
	const navigate = useNavigate();

	// Close the modal once actionData changes (and modal is open)
	useEffect(() => {
		if (actionData && isPublishModalOpen) {
			setIsPublishModalOpen(false);
		}
	}, [actionData]);

	const publishCapacityMonthlyProcess = data.processes.find(
		(p) => p.code === 'publish-capacity-monthly'
	);

	const capacityCloseOutProcess = data.processes.find(
		(p) => p.code === 'capacity-close-out'
	);

	const systemBusy = data.systemOverview.status.code !== 'idle';

	const publishState = systemButtonState(
		CapacityMonthlyPublishAction.Publish,
		navigation,
		systemBusy || publishCapacityMonthlyProcess?.status.code === 'paused'
	);

	const capacityCloseOutState = systemButtonState(
		CapacityMonthlyPublishAction.CapacityCloseOut,
		navigation,
		systemBusy || capacityCloseOutProcess?.status.code === 'paused'
	);

	const activeMetrics = data.monthlyMetrics;

	const comparisonColumns = [
		comparisonColumnHelper.accessor('label', {
			id: 'label',
			header: 'Metric',
			meta: { pinned: 'left' },
			size: 180,
		}),
		...activeMetrics.executionPeriods.map(
			(executionPeriod, executionPeriodIndex) => {
				return comparisonColumnHelper.display({
					id: executionPeriod.toString(),
					header: ReactDOMServer.renderToString(
						<YearMonthFragment yearMonth={executionPeriod} />
					),
					size: executionPeriodIndex === 0 ? 130 : undefined,
					minSize: executionPeriodIndex === 0 ? 130 : undefined,
					maxSize: executionPeriodIndex === 0 ? 130 : undefined,
					meta: {
						contentAlignment: 'right',
						headerAlignment: 'right',
						highlightHeader: executionPeriod === data.publishMonth.yearMonth,
						pinned: executionPeriodIndex === 0 ? 'left' : undefined,
						tooltip: undefined,
					},
					cell: ({ row }) => {
						return (
							<div style={{ padding: '10px 0', fontFamily: 'monospace' }}>
								{row.original.items.map((item, itemIndex) => {
									const data =
										row.original.items[itemIndex].data[executionPeriodIndex];

									return (
										<div key={item.label}>
											<NumberFragment
												value={data.value}
												decimalPlaces={row.original.decimalDigits}
											/>
											{row.original.type === 'percentage' && '%'}
											{executionPeriodIndex !== 0 &&
												data.change !== -1 &&
												data.change !== null && (
													<span
														style={{
															color:
																data.change < 0
																	? 'var(--color-red-500)'
																	: 'var(--color-green-600)',
														}}
													>
														{' '}
														[{data.change > 0 ? '+' : ''}
														<NumberFragment
															value={data.change}
															decimalPlaces={2}
															minDigits={2}
														/>
														{row.original.type !== 'percentage' ? '%' : ''}]
													</span>
												)}
										</div>
									);
								})}
							</div>
						);
					},
				});
			}
		),
	];

	return (
		<div className="content">
			<PageHeader
				title={
					<>
						{'Publish Capacity Summary – '}
						<YearMonthFragment yearMonth={data.publishMonth.yearMonth} />
					</>
				}
			>
				<RevalidationButton>Refresh</RevalidationButton>

				<ValidatedForm method="get">
					<DropdownField
						name="yearMonth"
						options={data.publishOptions}
						key="value"
						contentSource={(data) => data.label}
						initialValue={{
							value: data.publishMonth.yearMonth.toString(),
							label: data.publishMonth.description,
						}}
						identifierKey="value"
						isClearable={false}
						onOptionSelected={(option) => {
							navigate('?yearMonth=' + option?.value);
						}}
					/>
				</ValidatedForm>

				<Button
					type="button"
					isDisabled={publishState.isDisabled}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						setIsPublishModalOpen(true);
					}}
				>
					Publish Monthly Capacity Data
				</Button>
			</PageHeader>
			<PublishCapacityMonthlyDataModal
				publishMonth={data.publishMonth}
				isOpen={isPublishModalOpen}
				onClose={() => setIsPublishModalOpen(false)}
			/>

			<KeyValuePairs
				entries={[
					{
						key: data.systemOverview.name,
						value: (
							<BulletIndicator
								intent={systemOverviewIntent(data.systemOverview.status.code)}
								label={data.systemOverview.status.name}
							/>
						),
					},
					capacityCloseOutProcess
						? {
								key: capacityCloseOutProcess.name,
								value: (
									<BulletIndicator
										intent={blockingProcessIntent(
											capacityCloseOutProcess.status.code
										)}
										label={capacityCloseOutProcess.status.name}
									/>
								),
							}
						: null,
					publishCapacityMonthlyProcess
						? {
								key: publishCapacityMonthlyProcess.name,
								value: (
									<BulletIndicator
										intent={blockingProcessIntent(
											publishCapacityMonthlyProcess.status.code
										)}
										label={publishCapacityMonthlyProcess.status.name}
									/>
								),
							}
						: null,
				].filter((w): w is { key: string; value: JSX.Element } => w !== null)}
			/>

			{actionData && (
				<Grid columns={1}>
					{'errorCode' in actionData && actionData.errorCode && (
						<Alert intent="error" title={'Error'}>
							{actionData.message}
						</Alert>
					)}
					{'successCode' in actionData && actionData.successCode && (
						<Alert intent="success" title={'Success'}>
							{actionData.message}
						</Alert>
					)}
				</Grid>
			)}

			<Grid columns="1fr 3fr">
				<Grid>
					<Card label="Files Pending Ingestion">
						<Flex direction="column" gap={12}>
							<ValidatedForm method="post">
								<Grid>
									<Button
										variant="secondary"
										name="action"
										value={CapacityMonthlyPublishAction.CapacityCloseOut}
										isDisabled={capacityCloseOutState.isDisabled}
										isLoading={capacityCloseOutState.isLoading}
									>
										Process Capacity Data
									</Button>
								</Grid>
							</ValidatedForm>
						</Flex>
					</Card>
				</Grid>

				<Grid>
					<Card label={'Month Comparison'} isUnpadded isTransparent>
						<Table
							columns={comparisonColumns}
							data={activeMetrics.metrics}
							identifierKey="label"
						/>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

export const CAPACITY_SUMMARY_DASHBOARD_ROUTE: LayoutRouteProps = {
	loader: loader,
	action,
	element: <CapacitySummaryDashboard />,
	handle: {
		breadcrumbs: () => {
			return { label: 'Publish Capacity Summary' };
		},
	},
};
