import {
	createContext,
	Dispatch,
	PropsWithChildren,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import AirlinePicker from 'ui/components/AirlinePicker';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField/CheckboxField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import FreightForwarderPicker from 'ui/components/FreightForwarderPicker';
import Grid from 'ui/components/Grid';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import Modal from 'ui/components/Modal/Modal';
import MultiSelectField from 'ui/components/MultiSelectField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { Location } from 'utils/api/common';
import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import { LocationType } from 'utils/api/LocationAPI';
import { LabeledValue } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import {
	CustomerNddContributorOption,
	nddContributorsByCustomerId,
	WebToolDataGroupDetails,
} from '../../api/SubscriptionAPI';
import { ProductTypeCode } from '../../util/schemas/subscriptionSchema';

export interface WebToolConfigurationEditorContextType {
	webtoolEditorData: WebToolConfigurationEditorData;
	dispatch: Dispatch<WebToolConfigurationEditorActions>;
}

export type EditableWebToolDataGroup = Partial<WebToolDataGroupAllOptions> & {
	id: string;
};

type WebToolConfigurationEditorData = {
	dataGroups: EditableWebToolDataGroup[];
	mode: 'single' | 'multiple';
	editingId: string | null;
	dataSource?: string;
};

type WebToolDataGroupAllOptions = {
	airlines: AirlineOption[];
	freightForwarders: FreightForwarderSingleOption[];
	nddContributors: LabeledValue[];
	origin: Location[];
	destination: Location[];
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	includeReverseRoutes: boolean;
};

export type WebToolConfigurationEditorActions =
	| {
			type: 'create';
	  }
	| {
			type: 'remove';
			payload: {
				id: string;
			};
	  }
	| {
			type: 'patch';
			payload: EditableWebToolDataGroup;
	  }
	| {
			type: 'toggle-mode';
	  }
	| {
			type: 'edit';
			payload: {
				id: string;
			};
	  }
	| {
			type: 'close-editor';
	  }
	| {
			type: 'set-data-source';
			payload: string | undefined;
	  };

function neverReducer<T>(state: T, action: never): T {
	return state;
}

const createNewId = (function () {
	let seed = 1;
	return () => {
		return (seed++).toString();
	};
})();

function configurationReducer(
	state: WebToolConfigurationEditorData,
	action: WebToolConfigurationEditorActions
): WebToolConfigurationEditorData {
	switch (action.type) {
		case 'toggle-mode': {
			const newMode = state.mode === 'multiple' ? 'single' : 'multiple';

			return {
				...state,
				mode: newMode,
				dataGroups:
					newMode === 'single' && state.dataGroups.length === 0
						? [{ id: '1' }]
						: state.dataGroups,
			};
		}
		case 'edit':
			if (state.dataGroups.find((dg) => dg.id === action.payload.id)) {
				return {
					...state,
					editingId: action.payload.id,
				};
			}
			return state;
		case 'close-editor': {
			return {
				...state,
				editingId: null,
			};
		}
		case 'create': {
			const newId = createNewId();
			return {
				...state,
				dataGroups: [
					...state.dataGroups,
					{
						id: newId,
					},
				],
				editingId: newId,
			};
		}
		case 'remove':
			return {
				...state,
				dataGroups: state.dataGroups.filter(
					(dataGroup) => dataGroup.id !== action.payload.id
				),
			};
		case 'patch':
			return {
				...state,
				dataGroups: state.dataGroups.map((group) => {
					if (group.id === action.payload.id) {
						return {
							...group,
							...action.payload,
						};
					}

					return group;
				}),
			};
		case 'set-data-source':
			return {
				...state,
				dataSource: action.payload,
			};
		default:
			return neverReducer(state, action);
	}
}
export const WebToolSubscriptionEditorContext =
	createContext<WebToolConfigurationEditorContextType>(null!);

export function useWebToolSubscriptionEditor() {
	const context = useContext(WebToolSubscriptionEditorContext);
	if (context === undefined) {
		throw new Error('useWebToolData must be used within a CountProvider');
	}
	return context;
}

export function WebToolSubscriptionPeriodCalculated() {
	const { webtoolEditorData } = useWebToolSubscriptionEditor();
	const [start, end] = useMemo(() => {
		const relevantGroups =
			webtoolEditorData.mode === 'single'
				? webtoolEditorData.dataGroups.slice(0, 1)
				: webtoolEditorData.dataGroups;
		const all = relevantGroups
			.map((g) => g.startPeriod)
			.concat(relevantGroups.map((g) => g.endPeriod))
			.filter((yearMonth): yearMonth is YearAndMonth => Boolean(yearMonth));
		all.sort((a, b) => {
			if (a.year > b.year) return 1;
			if (a.year < b.year) return -1;
			if (a.month > b.month) return 1;
			if (a.month < b.month) return -1;
			return 0;
		});
		const [start, end] =
			all.length === 0 ? [null, null] : [all[0], all[all.length - 1]];
		return [start, end];
	}, [webtoolEditorData.dataGroups, webtoolEditorData.mode]);

	return (
		<>
			<Grid columns={2}>
				<Grid>
					<YearMonthPicker
						name="startPeriod"
						label="Start Period"
						value={start}
						placeholder="Automatically calculated"
						disabled
					/>
				</Grid>
				<Grid>
					<YearMonthPicker
						name="endPeriod"
						label="End Period"
						value={end}
						placeholder="Automatically calculated"
						disabled
					/>
				</Grid>
			</Grid>
		</>
	);
}

const ALLOWED_LOCATION_TYPES = [
	LocationType.STATION,
	LocationType.CITY,
	LocationType.PROVINCE,
	LocationType.COUNTRY,
	LocationType.REGION,
];

type WebToolSubscriptionGroupEditorProps = {
	namePrefix: string;
	group: EditableWebToolDataGroup;
	dispatch: React.Dispatch<WebToolConfigurationEditorActions>;
	productType: ProductTypeCode;
	customerId?: string;
	customerNddContributorOptions: CustomerNddContributorOption[];
	webToolDataSource?: string;
};

export function WebToolSubscriptionGroupEditor({
	namePrefix,
	group,
	productType,
	customerId,
	customerNddContributorOptions,
	webToolDataSource,
	dispatch,
}: WebToolSubscriptionGroupEditorProps) {
	const { revalidate } = useValidation(undefined);

	const nddContributorOptions =
		customerId === null
			? []
			: nddContributorsByCustomerId(customerNddContributorOptions, customerId);

	return (
		<Grid>
			<input type="hidden" name={`${namePrefix}.id`} value={group.id} />
			{productType === 'web-tool-airline' && (
				<AirlinePicker
					name={`${namePrefix}.airlines`}
					label="Airlines"
					isRequired
					isMulti={true}
					selectedAirlines={group.airlines ?? []}
					onSelectedAirlinesChange={(airlines) => {
						dispatch({
							type: 'patch',
							payload: {
								id: group.id,
								airlines: airlines,
							},
						});
						revalidate();
					}}
				/>
			)}

			{productType === 'web-tool-freight-forwarder' && (
				<>
					<FreightForwarderPicker
						name={`${namePrefix}.freightForwarders`}
						label="Freight Forwarders"
						isMulti={true}
						isRequired={webToolDataSource !== 'ndd'}
						freightForwarderTypes={['head-office', 'group', 'branch']}
						selectedFreightForwarders={group.freightForwarders ?? []}
						onSelectedFreightForwardersChange={(freightForwarders) => {
							dispatch({
								type: 'patch',
								payload: {
									id: group.id,
									freightForwarders:
										freightForwarders as FreightForwarderSingleOption[],
								},
							});
						}}
					/>

					{webToolDataSource === 'ndd' && (
						<MultiSelectField<LabeledValue>
							label="NDD Contributors"
							name={`${namePrefix}.nddContributors`}
							identifierKey="value"
							contentSource={(data) => data.label}
							options={nddContributorOptions ?? []}
							pillName={(item) => item.label}
							selectedOptions={group.nddContributors ?? []}
							onSelectedOptionsChange={(selected) => {
								dispatch({
									type: 'patch',
									payload: {
										id: group.id,
										nddContributors: selected,
									},
								});
							}}
						/>
					)}
				</>
			)}

			<LocationPicker
				name={`${namePrefix}.origin`}
				label="Origin"
				isMulti={true}
				locationTypes={ALLOWED_LOCATION_TYPES}
				selectedLocations={group.origin ?? []}
				onSelectedLocationsChange={(locations) => {
					dispatch({
						type: 'patch',
						payload: {
							id: group.id,
							origin: locations ?? [],
						},
					});
				}}
			/>

			<LocationPicker
				name={`${namePrefix}.destination`}
				label="Destination"
				isMulti={true}
				locationTypes={ALLOWED_LOCATION_TYPES}
				selectedLocations={group.destination ?? []}
				onSelectedLocationsChange={(locations) => {
					dispatch({
						type: 'patch',
						payload: {
							id: group.id,
							destination: locations ?? [],
						},
					});
				}}
			/>

			<CheckboxField
				name={`${namePrefix}.includeReverseRoutes`}
				label="Include Reverse Routes"
				value="true"
				defaultChecked={group.includeReverseRoutes}
				onChange={(e) => {
					dispatch({
						type: 'patch',
						payload: {
							id: group.id,
							includeReverseRoutes: e.target.checked,
						},
					});
				}}
			/>

			<Grid columns={2}>
				<Grid>
					<YearMonthPicker
						name={`${namePrefix}.startPeriod`}
						label="Start Period"
						isRequired={true}
						value={group.startPeriod}
						onChange={(value) => {
							dispatch({
								type: 'patch',
								payload: {
									id: group.id,
									startPeriod: value ? value : undefined,
								},
							});
						}}
					/>
				</Grid>
				<Grid>
					<YearMonthPicker
						name={`${namePrefix}.endPeriod`}
						label="End Period"
						isRequired={true}
						value={group.endPeriod}
						onChange={(value) => {
							dispatch({
								type: 'patch',
								payload: {
									id: group.id,
									endPeriod: value ? value : undefined,
								},
							});
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

export function WebToolConfigurationEditorProvider({
	children,
	dataGroups,
	productType,
	customerId,
	customerNddContributorOptions,
	webToolDataSource,
}: PropsWithChildren<{
	dataGroups: Partial<WebToolDataGroupDetails>[];
	productType: ProductTypeCode | undefined;
	customerId?: string;
	customerNddContributorOptions: CustomerNddContributorOption[];
	webToolDataSource?: string;
}>) {
	const [webtoolEditorData, dispatch] = useReducer(configurationReducer, {
		dataGroups: dataGroups.map((group) => ({ ...group, id: createNewId() })),
		mode: dataGroups.length > 1 ? 'multiple' : 'single',
		editingId: null,
		dataSource: webToolDataSource,
	} as WebToolConfigurationEditorData);

	const editingGroup = useMemo(() => {
		return webtoolEditorData.editingId
			? webtoolEditorData.dataGroups.find(
					(g) => g.id === webtoolEditorData.editingId
				)
			: null;
	}, [webtoolEditorData]);

	const providerValue = useMemo(
		() => ({ webtoolEditorData, dispatch }),
		[webtoolEditorData, dispatch]
	);

	return (
		<WebToolSubscriptionEditorContext.Provider value={providerValue}>
			{children}

			{editingGroup && productType && (
				<Modal
					title={'Edit Grouping'}
					isOpen
					onClose={function (): void {
						dispatch({ type: 'close-editor' });
					}}
				>
					<Modal.Body>
						<WebToolSubscriptionGroupEditor
							group={editingGroup}
							dispatch={dispatch}
							namePrefix={'edit'}
							productType={productType}
							customerId={customerId}
							customerNddContributorOptions={customerNddContributorOptions}
							webToolDataSource={webtoolEditorData.dataSource}
						/>
					</Modal.Body>
					<Modal.Actions>
						<Button
							onClick={() => {
								dispatch({ type: 'close-editor' });
							}}
						>
							Close
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</WebToolSubscriptionEditorContext.Provider>
	);
}
