import classNames from 'classnames';
import { LucideIcon, MoreHorizontal, X } from 'lucide-react';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from 'ui/components/Menu';
import { TabAction, Tab as TabType } from './Tabs';

type TabProps = {
	tab: TabType;
	end?: boolean;
	onActivate?: () => void;
	onClose?: () => void;
	baseUrl?: string;
	icon?: LucideIcon;
};

const Tab = ({
	tab,
	end = true,
	onActivate,
	baseUrl,
	onClose,
	icon: TabIcon,
}: TabProps) => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const handleClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		onClose?.();
	};

	const getActionHandler = (action: TabAction) => (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		action.onExecute();
	};

	const hasActions = (tab.actions && tab.actions.length > 0) || tab.isCloseable;

	const [normalActions, destructiveActions] = tab.actions?.reduce(
		(acc, action) => {
			if (action.isDestructive) {
				acc[1].push(action);
			} else {
				acc[0].push(action);
			}
			return acc;
		},
		[[], []] as [TabAction[], TabAction[]]
	) ?? [[], []];

	return (
		<li className="tabs__item tab" onClickCapture={onActivate}>
			<NavLink
				to={baseUrl + tab.to}
				end={end}
				className={({ isActive }) =>
					classNames(
						'tab__link',
						isActive && 'tab__link--active',
						isMenuOpen && 'tab__link--open-menu'
					)
				}
			>
				{TabIcon && <TabIcon size={16} className="tab__icon" />}

				<span className="tab__name" title={tab.name}>
					{tab.name}
				</span>

				{hasActions && (
					<div className="tab__actions">
						<Menu isOpen={isMenuOpen} onOpenChange={setMenuOpen}>
							<Menu.Trigger>
								<button className="tab__action" title="Actions">
									<MoreHorizontal size={16} />
								</button>
							</Menu.Trigger>
							{normalActions.map((action) => (
								<Menu.Item
									key={action.label}
									label={action.label}
									className="tab__menu-item"
									onClick={getActionHandler(action)}
								/>
							))}
							{destructiveActions.map((action) => (
								<Menu.Item
									key={action.label}
									label={action.label}
									className="tab__menu-item tab__menu-item--destructive"
									onClick={getActionHandler(action)}
								/>
							))}
						</Menu>
						{tab.isCloseable && (
							<button
								className="tab__action tab__action--close"
								onClick={handleClose}
							>
								<X size={16} />
							</button>
						)}
					</div>
				)}
			</NavLink>
		</li>
	);
};

export default Tab;
