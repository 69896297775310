import { FileSpreadsheet } from 'lucide-react';
import { useState } from 'react';
import CheckboxField from 'ui/components/CheckboxField/CheckboxField';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { LabeledValue } from 'utils/types/common';

type MonthlyReportsModalProps = {
	reportType: LabeledValue;
	totalReports: number;
	productTypes: string[];
	customerIds: string[];
	reportDateYear: number;
	reportDateMonth: number;
} & Omit<ModalProps, 'title'>;

const MonthlyReportsModal = ({
	reportType,
	totalReports,
	productTypes,
	customerIds,
	reportDateYear,
	reportDateMonth,
	...modalProps
}: MonthlyReportsModalProps) => {
	const [runOnNewData, setRunOnNewData] = useState(false);

	return (
		<ConfirmationModal
			fieldName="generate"
			title={`Run Monthly ${reportType.label} Reports`}
			submitText="Run Reports"
			submitIcon={FileSpreadsheet}
			confirmationText="Generate"
			secondaryChildren={
				<CheckboxField
					name="runOnNewDataScheduleRaw"
					label={`Additionally run ${reportType.label} 'On New Data' Scheduled Reports`}
					checked={runOnNewData}
					onChange={(e) => setRunOnNewData(e.target.checked)}
				/>
			}
			formMethod="post"
			{...modalProps}
		>
			{productTypes.map((productType) => (
				<input
					type="hidden"
					name="productTypes"
					key={productType}
					value={productType}
				/>
			))}

			{customerIds.map((customerId) => (
				<input
					type="hidden"
					name="customerIds"
					key={customerId}
					value={customerId}
				/>
			))}

			<input type="hidden" name="reportDate.month" value={reportDateMonth} />
			<input type="hidden" name="reportDate.year" value={reportDateYear} />
			<input type="hidden" name="reportType" value={reportType.value} />

			{runOnNewData && (
				<input type="hidden" name="runOnNewDataSchedule" value="true" />
			)}

			<p>
				Are you sure you wish to generate {totalReports}{' '}
				<strong>{reportType.label}</strong> reports?
			</p>
		</ConfirmationModal>
	);
};

export default MonthlyReportsModal;
