import { createColumnHelper } from '@tanstack/react-table';
import { Locale, endOfWeek, format, getWeek, startOfWeek } from 'date-fns';
import { CalendarClock, Siren } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	Navigation,
	useActionData,
	useLoaderData,
	useNavigate,
	useNavigation,
} from 'react-router-dom';
import Alert from 'ui/components/Alert';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import NumberFragment from 'ui/components/NumberFragment';
import PageHeader from 'ui/components/PageHeader';
import Pill from 'ui/components/Pill';
import PillDrawer from 'ui/components/PillDrawer';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import ValidatedForm from 'ui/components/ValidatedForm';
import APIError from 'utils/errors/APIError';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import DataManagementAPI, {
	CommonLambdaResponse,
	MetricEntry,
} from '../../api/DataManagementAPI';
import {
	YearMonthFragment,
	getYearMonthFromQueryStringOrDefault,
} from '../../components/YearMonth/YearMonthFragment';
import { YearMonthWeekFragment } from '../../components/YearMonth/YearMonthWeekFragment';
import { getYearMonthWeekFromQueryStringOrDefault } from '../../components/YearMonthWeek/YearMonthWeekFragment';
import { DropdownField } from '../../forms/DropdownField';
import {
	blockingProcessIntent,
	systemOverviewIntent,
} from '../monitor/system-status';
import PublishMonthlyDataModal from './PublishMonthlyDataModal';
import PublishWeeklyDataModal from './PublishWeeklyDataModal';

const WEEK_LOCALE: Locale = {
	options: {
		weekStartsOn: 1,
		firstWeekContainsDate: 7,
	},
};

type LoaderData = Awaited<ReturnType<typeof loader>>;
type ActionData = Awaited<ReturnType<typeof action>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const yearMonth = getYearMonthFromQueryStringOrDefault(request);
	const yearMonthWeek = getYearMonthWeekFromQueryStringOrDefault(request);

	return await DataManagementAPI.publishSummary(yearMonth, yearMonthWeek);
};

export const action = async ({
	request,
}: ActionFunctionArgs): Promise<CommonLambdaResponse | null> => {
	let apiResponse: CommonLambdaResponse | Error | APIError | null = null;
	const formData = await request.formData();

	switch (formData.get('action')) {
		case MonthlyPublishAction.Consolidation:
			apiResponse = await DataManagementAPI.startCassConsolidation();
			break;
		case MonthlyPublishAction.Publish: {
			const yearMonth = Number(formData.get('yearMonth'));
			apiResponse = await DataManagementAPI.startPublishMonthlyData(yearMonth);
			break;
		}
		case WeeklyPublishAction.Publish: {
			const yearMonthWeek = Number(formData.get('yearMonthWeek'));
			apiResponse =
				await DataManagementAPI.startPublishWeeklyData(yearMonthWeek);
			break;
		}
	}

	if (!apiResponse) return null;

	// Bring any message into view
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});

	if ('fieldErrors' in apiResponse) {
		return {
			errorCode: 'field-errors',
			successCode: null,
			message: 'There were errors with the data you submitted.',
			fieldErrors: apiResponse.fieldErrors,
		} as CommonLambdaResponse;
	}

	if (apiResponse instanceof Error) {
		return null;
	}

	return apiResponse;
};

export enum MonthlyPublishAction {
	Consolidation = 'caas-consolidation',
	Publish = 'publish-monthly',
}

export enum WeeklyPublishAction {
	Publish = 'publish-weekly',
}

export function isSubmittingSystemAction(
	action: MonthlyPublishAction,
	navigation: Navigation
) {
	return (
		navigation.state !== 'idle' &&
		navigation.formAction === '/publish/monthly' &&
		navigation.formData?.get('action') === action
	);
}

function systemButtonState(
	action: MonthlyPublishAction,
	navigation: Navigation,
	systemBusy: boolean
) {
	const isDisabled =
		systemBusy ||
		(navigation.state !== 'idle' &&
			navigation.formAction === '/publish/monthly' &&
			navigation.formData?.get('action') !== action);

	return {
		isDisabled,
		isLoading: isSubmittingSystemAction(action, navigation),
	};
}

const ingestedFilesColumnHelper = createColumnHelper<{
	type: string;
	name: string;
	lastIngested: Date | null;
	count: number;
}>();

const ingestedColumns = [
	ingestedFilesColumnHelper.accessor('name', { header: 'File Type' }),
	ingestedFilesColumnHelper.accessor('lastIngested', {
		header: 'Last Ingested (UTC)',
		cell: ({ getValue }) => (
			<DateFragment
				date={getValue()}
				includeTime={true}
				emptyText="-"
				timezone="utc"
			/>
		),
	}),
	ingestedFilesColumnHelper.accessor('count', { header: 'Count' }),
];

const comparisonColumnHelper = createColumnHelper<MetricEntry>();

export function SummaryDashboard() {
	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
	const [isPublishWeeklyModalOpen, setIsPublishWeeklyModalOpen] =
		useState(false);
	const data = useLoaderData() as LoaderData;
	const actionData = useActionData() as ActionData;
	const navigation = useNavigation();
	const navigate = useNavigate();

	const [viewMode, setViewMode] = useState<'monthly' | 'weekly'>('monthly');

	// Close the modal once actionData changes (and modal is open)
	useEffect(() => {
		if (actionData && isPublishModalOpen) {
			setIsPublishModalOpen(false);
		}
		if (actionData && isPublishWeeklyModalOpen) {
			setIsPublishWeeklyModalOpen(false);
		}
	}, [actionData]);

	const cassConsolidationProcess = data.processes.find(
		(p) => p.code === 'cass-consolidation'
	);
	const publishMonthlyProcess = data.processes.find(
		(p) => p.code === 'publish-monthly'
	);
	const publishWeeklyProcess = data.processes.find(
		(p) => p.code === 'publish-weekly'
	);
	const systemBusy = data.systemOverview.status.code !== 'idle';
	const consolidationState = systemButtonState(
		MonthlyPublishAction.Consolidation,
		navigation,
		systemBusy || cassConsolidationProcess?.status.code === 'paused'
	);
	const publishState = systemButtonState(
		MonthlyPublishAction.Publish,
		navigation,
		systemBusy || publishMonthlyProcess?.status.code === 'paused'
	);

	const publishWeeklyState = systemButtonState(
		MonthlyPublishAction.Publish,
		navigation,
		systemBusy || publishWeeklyProcess?.status.code === 'paused'
	);

	const activeMetrics =
		viewMode === 'monthly' ? data.monthlyMetrics : data.weeklyMetrics;

	const getStartAndEndDateOfWeek = (yearMonthWeek: number) => {
		// Use locale as above
		const week = yearMonthWeek.toString().slice(6, 8);
		const year = yearMonthWeek.toString().slice(0, 4);

		let weekDate = new Date(parseInt(year), 0, 1);
		while (
			getWeek(weekDate, {
				locale: WEEK_LOCALE,
			}) !== parseInt(week)
		) {
			weekDate = new Date(weekDate.setDate(weekDate.getDate() + 1));
		}

		return {
			weekStart: startOfWeek(weekDate, { locale: WEEK_LOCALE }),
			weekEnd: endOfWeek(weekDate, { locale: WEEK_LOCALE }),
		};
	};

	const getWeekRangeString = (yearMonthWeek: number) => {
		const { weekStart, weekEnd } = getStartAndEndDateOfWeek(yearMonthWeek);

		const dateFormat = 'dd MMM yyyy';
		const weekStartString = format(weekStart, dateFormat);
		const weekEndString = format(weekEnd, dateFormat);

		return `${weekStartString} - ${weekEndString}`;
	};

	const comparisonColumns = [
		comparisonColumnHelper.accessor('label', {
			id: 'label',
			header: 'Metric',
			meta: { pinned: 'left' },
			size: 180,
		}),
		comparisonColumnHelper.accessor('items', {
			id: 'items',
			header: '',
			cell: ({ getValue }) => (
				<div style={{ padding: '10px 0' }}>
					{getValue().map((item) => (
						<div key={item.label}>{item.label}</div>
					))}
				</div>
			),
			meta: { pinned: 'left' },
			size: 180,
		}),

		...activeMetrics.executionPeriods.map(
			(executionPeriod, executionPeriodIndex) => {
				return comparisonColumnHelper.display({
					id: executionPeriod.toString(),
					header: ReactDOMServer.renderToString(
						viewMode === 'monthly' ? (
							<YearMonthFragment yearMonth={executionPeriod} />
						) : (
							<YearMonthWeekFragment value={executionPeriod} />
						)
					),
					size: executionPeriodIndex === 0 ? 130 : undefined,
					minSize: executionPeriodIndex === 0 ? 130 : undefined,
					maxSize: executionPeriodIndex === 0 ? 130 : undefined,
					meta: {
						contentAlignment: 'right',
						headerAlignment: 'right',
						highlightHeader:
							viewMode === 'monthly'
								? executionPeriod === data.publishMonth.yearMonth
								: executionPeriodIndex === 0,
						pinned: executionPeriodIndex === 0 ? 'left' : undefined,
						tooltip:
							viewMode === 'weekly'
								? getWeekRangeString(executionPeriod)
								: undefined,
					},
					cell: ({ row }) => {
						return (
							<div style={{ padding: '10px 0', fontFamily: 'monospace' }}>
								{row.original.items.map((item, itemIndex) => {
									const data =
										row.original.items[itemIndex].data[executionPeriodIndex];

									return (
										<div key={item.label}>
											<NumberFragment
												value={data.value}
												decimalPlaces={row.original.decimalDigits}
											/>
											{row.original.type === 'percentage' && '%'}
											{data.change !== -1 && data.change !== null && (
												<span
													style={{
														color:
															data.change < 0
																? 'var(--color-red-500)'
																: 'var(--color-green-600)',
													}}
												>
													{' '}
													[{data.change > 0 ? '+' : ''}
													<NumberFragment
														value={data.change}
														decimalPlaces={2}
														minDigits={2}
													/>
													{row.original.type !== 'percentage' ? '%' : ''}]
												</span>
											)}
										</div>
									);
								})}
							</div>
						);
					},
				});
			}
		),
	];

	return (
		<div className="content">
			<PageHeader
				title={
					<>
						{'Publish Summary – '}
						<YearMonthFragment yearMonth={data.publishMonth.yearMonth} />
					</>
				}
			>
				<RevalidationButton>Refresh</RevalidationButton>

				<ValidatedForm method="get">
					<DropdownField
						name="yearMonthWeek"
						options={data.publishWeekOptions}
						key="value"
						contentSource={(data) => data.label}
						initialValue={{
							value: data.publishWeek.yearMonthWeek.toString(),
							label: data.publishWeek.description,
						}}
						identifierKey="value"
						isClearable={false}
						onOptionSelected={(option) => {
							navigate(
								'?yearMonth=' +
									data.publishMonth.yearMonth +
									'&yearMonthWeek=' +
									option?.value
							);
						}}
					/>
				</ValidatedForm>

				<Button
					type="button"
					isDisabled={publishWeeklyState.isDisabled}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						setIsPublishWeeklyModalOpen(true);
					}}
				>
					Publish Weekly Data
				</Button>

				<ValidatedForm method="get">
					<DropdownField
						name="yearMonth"
						options={data.publishOptions}
						key="value"
						contentSource={(data) => data.label}
						initialValue={{
							value: data.publishMonth.yearMonth.toString(),
							label: data.publishMonth.description,
						}}
						identifierKey="value"
						isClearable={false}
						onOptionSelected={(option) => {
							navigate(
								'?yearMonth=' +
									option?.value +
									'&yearMonthWeek=' +
									data.publishWeek.yearMonthWeek
							);
						}}
					/>
				</ValidatedForm>

				<Button
					type="button"
					isDisabled={publishState.isDisabled}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						setIsPublishModalOpen(true);
					}}
				>
					Publish Monthly Data
				</Button>
			</PageHeader>
			<PublishMonthlyDataModal
				cassCalendarSummary={data.cassCalendarSummary}
				publishMonth={data.publishMonth}
				isOpen={isPublishModalOpen}
				onClose={() => setIsPublishModalOpen(false)}
			/>
			<PublishWeeklyDataModal
				publishWeek={data.publishWeek}
				isOpen={isPublishWeeklyModalOpen}
				onClose={() => setIsPublishWeeklyModalOpen(false)}
			/>

			<KeyValuePairs
				entries={[
					{
						key: data.systemOverview.name,
						value: (
							<BulletIndicator
								intent={systemOverviewIntent(data.systemOverview.status.code)}
								label={data.systemOverview.status.name}
							/>
						),
					},
					cassConsolidationProcess
						? {
								key: cassConsolidationProcess.name,
								value: (
									<BulletIndicator
										intent={blockingProcessIntent(
											cassConsolidationProcess.status.code
										)}
										label={cassConsolidationProcess.status.name}
									/>
								),
							}
						: null,
					publishMonthlyProcess
						? {
								key: publishMonthlyProcess.name,
								value: (
									<BulletIndicator
										intent={blockingProcessIntent(
											publishMonthlyProcess.status.code
										)}
										label={publishMonthlyProcess.status.name}
									/>
								),
							}
						: null,
					publishWeeklyProcess
						? {
								key: publishWeeklyProcess.name,
								value: (
									<BulletIndicator
										intent={blockingProcessIntent(
											publishWeeklyProcess.status.code
										)}
										label={publishWeeklyProcess.status.name}
									/>
								),
							}
						: null,
				].filter((w): w is { key: string; value: JSX.Element } => w !== null)}
			/>

			{actionData && (
				<Grid columns={1}>
					{'errorCode' in actionData && actionData.errorCode && (
						<Alert intent="error" title={'Error'}>
							{actionData.message}
						</Alert>
					)}
					{'successCode' in actionData && actionData.successCode && (
						<Alert intent="success" title={'Success'}>
							{actionData.message}
						</Alert>
					)}
				</Grid>
			)}

			<Grid columns="1fr 3fr">
				<Grid>
					<Card label="CASS HotFiles">
						<Flex direction="column" gap={12}>
							<Flex direction="column" gap={4}>
								<div>
									Missing{' '}
									<b>{data.cassCalendarSummary.billingFilesMissingCount}</b>{' '}
									billing submissions:{' '}
								</div>
								<PillDrawer>
									{data.cassCalendarSummary.billingFilesMissing.map((m) => (
										<Pill
											code={m.cassArea}
											name={m.submission.toString()}
											key={m.cassArea + m.submission.toString()}
										/>
									))}
								</PillDrawer>
							</Flex>

							<div>
								Missing{' '}
								<b>{data.cassCalendarSummary.correctionFilesMissingCount}</b>{' '}
								correction file(s).
							</div>

							<Button
								variant="secondary"
								to={`/monitoring/hotfile-monitor?reportDate.year=${data.publishMonth.year}&reportDate.month=${data.publishMonth.month}`}
								icon={Siren}
							>
								CASS HotFile Monitor
							</Button>
						</Flex>
					</Card>

					<Card label="CDD Submissions">
						<Flex direction="column" gap={12}>
							<Flex direction="column" gap={4}>
								<div>
									Missing{' '}
									<b>{data.cddCalendarSummary.missingSubmissionsCount}</b> CDD
									submissions(s):{' '}
								</div>
								<PillDrawer>
									{data.cddCalendarSummary.missingSubmissions.map(
										(contributor) => (
											<Pill
												key={contributor.codes.join(',')}
												name={contributor.codes
													.map((code) =>
														code.length === 5 ? code.substring(2) : code
													)
													.join(', ')}
											/>
										)
									)}
								</PillDrawer>
							</Flex>

							<Button
								variant="secondary"
								to={`/monitoring/cdd-monitor?reportDate.year=${data.publishMonth.year}&reportDate.month=${data.publishMonth.month}`}
								icon={Siren}
							>
								CDD Monitor
							</Button>
						</Flex>
					</Card>

					<Card label="Files Pending Consolidation">
						<Flex direction="column" gap={12}>
							<ul>
								<li>
									<b>{data.cassCalendarSummary.pendingFileCount}</b> CASS files
								</li>
								{data.cassCalendarSummary.pendingSubmissions.map(
									(submission) => (
										<li key={submission.submissionMonth}>
											<YearMonthFragment
												yearMonth={submission.submissionMonth}
											/>
											: <b>{submission.count}</b>
										</li>
									)
								)}
							</ul>

							<ul>
								<li>
									<b>{data.cddCalendarSummary.pendingConsolidationFileCount}</b>{' '}
									CDD files
								</li>
							</ul>

							<ValidatedForm method="post">
								<Grid>
									<Button
										variant="secondary"
										name="action"
										value={MonthlyPublishAction.Consolidation}
										isDisabled={
											consolidationState.isDisabled ||
											(data.cassCalendarSummary.pendingFileCount === 0 &&
												data.cddCalendarSummary
													.pendingConsolidationFileCount === 0)
										}
										isLoading={consolidationState.isLoading}
									>
										Consolidate Pending Files
									</Button>
								</Grid>
							</ValidatedForm>
						</Flex>
					</Card>

					<Card isTransparent isUnpadded label="Latest Files Ingested">
						<Table
							columns={ingestedColumns}
							data={data.ingestionQueues}
							identifierKey="type"
						/>
					</Card>
				</Grid>
				<Grid>
					<Card
						label={
							viewMode === 'monthly' ? 'Month Comparison' : 'Week Comparison'
						}
						secondaryLabel={
							<button
								className="link-button"
								onClick={() =>
									setViewMode((prev) =>
										prev === 'weekly' ? 'monthly' : 'weekly'
									)
								}
							>
								<CalendarClock size={14} />
								View {viewMode === 'monthly'
									? 'Weekly'
									: 'Monthly'} Data
							</button>
						}
						isUnpadded
						isTransparent
					>
						<Table
							columns={comparisonColumns}
							data={activeMetrics.metrics}
							identifierKey="label"
						/>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

export const SUMMARY_DASHBOARD_ROUTE: LayoutRouteProps = {
	loader: loader,
	action,
	element: <SummaryDashboard />,
	handle: {
		breadcrumbs: () => {
			return { label: 'Publish Summary' };
		},
	},
};
