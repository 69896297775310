import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator/BulletIndicator';
import Button from 'ui/components/Button/Button';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import Modal from 'ui/components/Modal/Modal';
import PageHeader from 'ui/components/PageHeader/PageHeader';
import Pagination from 'ui/components/Pagination';
import PrettyJSON from 'ui/components/PrettyJSON';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table/Table';
import TextField from 'ui/components/TextField/TextField';
import Toggle from 'ui/components/Toggle/Toggle';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import ValidatedForm from 'ui/components/ValidatedForm/ValidatedForm';
import formatBytes from 'utils/helpers/formatBytes';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import usePersistantState from 'utils/hooks/usePersistantState';
import { LoaderData } from 'utils/types/loaderData';
import DataMonitoringAPI, { Job, JobStep } from '../../api/DataMonitoringAPI';
import { DropdownField } from '../../forms/DropdownField';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const params = new URL(request.url).searchParams;

	const [jobsData, errorCodes] = await Promise.all([
		DataMonitoringAPI.getJobs(params),
		DataMonitoringAPI.getErrorCodes(),
	]);

	return {
		jobsData,
		errorCodes,
	};
};

export function MonitorIndex() {
	const { jobsData, errorCodes } = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	const [showOutcomeInline, setShowOutcomeInline] = usePersistantState(
		'monitor.showOutcomeInline',
		false
	);

	const [selectedJobDetails, setSelectedJobDetails] = useState<Job | null>(
		null
	);

	const jobColumnHelper = createColumnHelper<Job>();
	const jobStepColumnHelper = createColumnHelper<JobStep>();
	const sharedColumnHelper = createColumnHelper<Job | JobStep>();

	const statusColumn = sharedColumnHelper.accessor('status', {
		header: 'Status',
		cell: (info) => {
			let intent: 'info' | 'error' | 'success' = 'info';

			if (info.row.original.status.includes('FAILED')) {
				intent = 'error';
			} else if (info.row.original.completedAtUtc) {
				intent = 'success';
			}

			return (
				<BulletIndicator intent={intent} label={info.row.original.status} />
			);
		},
	});

	const startedAtColumn = sharedColumnHelper.display({
		header: 'Started at (UTC)',
		cell: (info) => (
			<DateFragment
				date={info.row.original.startedAtUtc}
				includeTime
				timezone="utc"
			/>
		),
	});

	const updatedAtColumn = sharedColumnHelper.display({
		header: 'Updated at (UTC)',
		cell: (info) => (
			<JobsMostRelevantDateTime
				startedAtUtc={info.row.original.startedAtUtc}
				updatedAtUtc={info.row.original.updatedAtUtc}
				completedAtUtc={info.row.original.completedAtUtc}
			/>
		),
	});

	const outcomeColumn = sharedColumnHelper.display({
		header: 'Outcome',
		meta: {
			shrink: true,
		},
		cell: (info) => {
			const [isPopoverOpen, setPopoverOpen] = useState(false);

			const job = info.row.original;

			let data: Record<string, any> | undefined =
				'steps' in job
					? job.steps.reduce((prev, cur) => ({ ...prev, ...cur.data }), {})
					: job.data;

			if (!data) return null;

			// Move all keys that aren't objects into a "general" key
			data = Object.entries(data).reduce(
				(prev, [key, value]) => {
					if (typeof value !== 'object') {
						return {
							...prev,
							general: { ...(prev.general || {}), [key]: value },
						};
					}

					return { ...prev, [key]: value };
				},
				{} as Record<string, any>
			);

			// Make sure error & alert codes are human readable
			for (const codeKey of ['errorCodeSummary', 'alertCodeSummary']) {
				if (codeKey in data && typeof data[codeKey] === 'object') {
					data[codeKey] = Object.entries(data[codeKey] as object).reduce(
						(prev, [key, value]) => ({
							...prev,
							[key]:
								key in errorCodes.errorCodes
									? `${errorCodes.errorCodes[key].title} (${errorCodes.errorCodes[key].description}, ${value})`
									: 'Unknown error',
						}),
						{} as Record<string, any>
					);
				}
			}

			const hasData = data && Object.values(data).some((v) => !!v);

			// Add error data
			const outcomeComponent = (
				<div style={{ padding: '8px 4px' }}>
					<PrettyJSON jsonObject={data} emptyText="–" gap={8} />
				</div>
			);

			return showOutcomeInline ? (
				outcomeComponent
			) : (
				<>
					{hasData ? (
						<Tooltip open={isPopoverOpen} onOpenChange={setPopoverOpen}>
							<Tooltip.Trigger>
								<Button
									size="tiny"
									variant="secondary"
									onClick={() => setPopoverOpen(true)}
								>
									View outcome
								</Button>
							</Tooltip.Trigger>
							<Tooltip.Content isInteractive>
								{outcomeComponent}
							</Tooltip.Content>
						</Tooltip>
					) : (
						'–'
					)}
				</>
			);
		},
	});

	const columns = [
		statusColumn,

		jobColumnHelper.display({
			header: 'Details',
			cell: (info) => {
				const job = info.row.original;

				if ('file' in job && job.file)
					return `${job.file.name} (${formatBytes(job.file.size)})`;

				return job.name;
			},
		}),

		jobColumnHelper.accessor('type', {
			header: 'Type',
			cell: (info) => {
				const job = info.row.original;

				return 'type' in job ? job.type.label : '–';
			},
		}),

		startedAtColumn,
		updatedAtColumn,
		outcomeColumn,

		jobColumnHelper.display({
			header: 'Steps',
			meta: {
				shrink: true,
			},
			cell: (info) => {
				return (
					<Button
						size="tiny"
						variant="secondary"
						onClick={() => setSelectedJobDetails(info.row.original)}
					>
						View steps
					</Button>
				);
			},
		}),
	];

	const jobStepColumns = [
		jobStepColumnHelper.display({
			header: 'Step',
			cell: (info) => info.row.index + 1,
		}),

		statusColumn,

		jobStepColumnHelper.display({
			header: 'Details',
			cell: (info) => info.row.original.name.label,
		}),

		startedAtColumn,
		updatedAtColumn,
		outcomeColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Monitoring">
					<Flex gap={20} alignItems="center">
						<Flex gap={8}>
							<span onClick={() => setShowOutcomeInline(!showOutcomeInline)}>
								Compact Mode
							</span>
							<Toggle
								isChecked={!showOutcomeInline}
								onChange={() => setShowOutcomeInline(!showOutcomeInline)}
							/>
						</Flex>
						<RevalidationButton>Refresh</RevalidationButton>
					</Flex>
				</PageHeader>

				{jobsData.jobs.total > 0 ? (
					<>
						<Table
							columns={columns as ColumnDef<Job>[]}
							data={jobsData.jobs.items}
							identifierKey="id"
						/>

						<Pagination
							baseUrl={new URL(window.location.href)}
							page={jobsData.jobs.page}
							pageParameterName="page"
							pageSize={jobsData.jobs.pageSize}
							itemCount={jobsData.jobs.total}
						/>
					</>
				) : (
					<>
						<p>No results found for those search parameters.</p>
					</>
				)}
			</div>

			<Modal
				isOpen={!!selectedJobDetails}
				onClose={() => setSelectedJobDetails(null)}
				title="Job Details"
				size="xl"
			>
				<Modal.Body>
					{selectedJobDetails && (
						<Table
							data={selectedJobDetails.steps}
							columns={jobStepColumns as ColumnDef<JobStep>[]}
							identifierKey="id"
						/>
					)}
				</Modal.Body>
			</Modal>

			<Sidebar title="Filter">
				<ValidatedForm resetOnNavigation>
					<TextField
						label="Filename"
						name="filename"
						defaultValue={jobsData.searchParameters.filename}
					/>

					<DropdownField
						label="Category"
						name="category"
						options={jobsData.categories}
						placeholder="All categories"
						identifierKey="value"
						contentSource="label"
						initialValue={
							jobsData.searchParameters.category
								? {
										value: jobsData.searchParameters.category,
										label: jobsData.searchParameters.category,
									}
								: undefined
						}
					/>

					<DropdownField
						label="Type"
						name="type"
						options={jobsData.types}
						placeholder="All types"
						identifierKey="value"
						contentSource="label"
						initialValue={
							jobsData.searchParameters.type
								? {
										value: jobsData.searchParameters.type,
										label: jobsData.searchParameters.type,
									}
								: undefined
						}
					/>

					<DropdownField
						label="Status"
						name="status"
						options={jobsData.status.map((status) => ({
							value: status,
							label: status,
						}))}
						placeholder="All statuses"
						identifierKey="value"
						contentSource="label"
						initialValue={
							jobsData.searchParameters.status
								? {
										value: jobsData.searchParameters.status,
										label: jobsData.searchParameters.status,
									}
								: undefined
						}
					/>

					<Sidebar.Actions>
						<Button type="reset" variant="secondary">
							Clear search
						</Button>
						<Button
							type="submit"
							variant="primary"
							disabled={navigation.state !== 'idle'}
						>
							{navigation.state === 'idle' ? 'Search' : 'Searching'}
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

declare interface ProgressDateTimeProps {
	startedAtUtc: Date;
	updatedAtUtc?: Date;
	completedAtUtc?: Date;
}

function JobsMostRelevantDateTime({
	startedAtUtc,
	updatedAtUtc,
	completedAtUtc,
}: ProgressDateTimeProps) {
	let message: string, relevantDateTime: Date;

	if (completedAtUtc) {
		relevantDateTime = completedAtUtc;
	} else if (updatedAtUtc) {
		relevantDateTime = updatedAtUtc;
	} else {
		return <>–</>;
	}

	return <DateFragment date={relevantDateTime} includeTime timezone="utc" />;
}
