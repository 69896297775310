import { Cog } from 'lucide-react';
import { useId, useState } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import ErrorList from 'ui/components/ErrorList';
import FormField from 'ui/components/FormField';
import Grid from 'ui/components/Grid';
import TabActions from 'ui/components/Tabs/TabActions';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';

import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LabeledValue } from 'utils/types/common';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI from '../../api/SubscriptionAPI';
import { DropdownField } from '../../forms/DropdownField';
import { generateReportSchema } from '../../util/schemas/reportSchema';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const subscriptionId = params.subscriptionId as string;

	const subscriptionData =
		await SubscriptionAPI.getSubscription(subscriptionId);

	return {
		subscription: subscriptionData,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const subscriptionId = params.subscriptionId as string;
	const formData = await request.formData();
	const reportModel = formDataAsObject(formData);

	const generateReportModel = generateReportSchema.parse(reportModel);

	const data = await SubscriptionAPI.generateReport(
		subscriptionId,
		generateReportModel
	);

	return data instanceof Error
		? data
		: redirect(`/subscriptions/${subscriptionId}/reports`);
};

const SubscriptionGenerateReport = () => {
	const loaderData = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const reportTypes = [
		{ label: 'Single Date', value: 'singleDate' },
		{ label: 'Date Range', value: 'dateRange' },
	];

	const [option, setOption] = useState<LabeledValue | null>({
		label: 'Single Date',
		value: 'singleDate',
	});

	const [showDateRangeFields, setShowDateRangeFields] =
		useState<boolean>(false);

	const [dateFieldLabel, setDateFieldLabel] = useState<string>('Report Date *');

	const isBip =
		loaderData.subscription.productConfiguration.type.startsWith('bip-');

	const isWeeklyPlusSubscription =
		loaderData.subscription.productConfiguration.type.endsWith('-weekly-plus');

	const showSuppressDistortionOption = (): boolean => {
		const revenueProducts = [
			'airport-financial-data-third-party',
			'bip-airline',
			'bip-airline-weekly',
			'bip-airline-weekly-plus',
			'bip-airline-cdd',
			'bip-airline-cdd-weekly',
			'bip-airline-cdd-weekly-plus',
			'bip-freight-forwarder',
			'bip-freight-forwarder-weekly',
			'bip-freight-forwarder-weekly-plus',
			'bip-freight-forwarder-cdd',
			'bip-freight-forwarder-cdd-weekly',
			'bip-freight-forwarder-cdd-weekly-plus',
			'bip-third-party-revenue',
			'bip-third-party-revenue-weekly',
			'market-focus-airline',
			'market-focus-airline-cdd',
			'market-focus-freight-forwarder',
			'market-focus-freight-forwarder-cdd',
			'standard-airline',
			'standard-freight-forwarder',
			'standard-third-party',
			'top-airline',
		];

		return revenueProducts.some(
			(option) => option === loaderData.subscription.productConfiguration.type
		);
	};

	const formId = useId();

	const minDate = new Date(
		loaderData.subscription.startPeriod.year,
		loaderData.subscription.startPeriod.month - 1,
		1
	);
	const maxDate = new Date(
		loaderData.subscription.latestYearMonthForReportGeneration.year,
		loaderData.subscription.latestYearMonthForReportGeneration.month - 1,
		1
	);

	return (
		<div>
			<ValidatedForm id={formId} method="post" validator={generateReportSchema}>
				<Grid>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2}>
							<DropdownField
								options={reportTypes}
								name="reportGenerationType"
								identifierKey="value"
								onOptionSelected={(option) => {
									if (option?.value === 'dateRange') {
										setShowDateRangeFields(true);
										setDateFieldLabel('Start Report Date *');
									} else {
										setShowDateRangeFields(false);
										setDateFieldLabel('Report Date *');
									}
									setOption(option);
								}}
								selectedOption={option}
								isClearable={false}
								label="Report Generation Type"
								isRequired
								initialValue={reportTypes[0]}
								contentSource={(data) => data.label}
							/>

							{isBip && (
								<FormField label="Preserve BIP Filename">
									<CheckboxField
										label={
											isWeeklyPlusSubscription
												? 'Include YYYYWW in filename'
												: 'Include YYYYMM in filename'
										}
										name="preserveFilename"
									/>
								</FormField>
							)}

							<FormField label={dateFieldLabel}>
								<YearMonthPicker
									name="startReportDate"
									minDate={minDate}
									maxDate={maxDate}
								/>
							</FormField>

							{showSuppressDistortionOption() && (
								<FormField label="Suppress Distortion">
									<CheckboxField
										label="Don't apply distortion"
										name="suppressDistortion"
									/>
								</FormField>
							)}

							{showDateRangeFields ? (
								<FormField label="End Report Date *">
									<YearMonthPicker
										name="endReportDate"
										minDate={minDate}
										maxDate={maxDate}
									/>
								</FormField>
							) : (
								<div />
							)}

							<TabActions>
								<Button
									type="submit"
									form={formId}
									isDisabled={navigation.state !== 'idle'}
									size="small"
									icon={Cog}
								>
									Generate report
								</Button>
							</TabActions>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
};

export const SUBSCRIPTION_GENERATE_REPORT_ROUTE: LayoutRouteProps = {
	loader: loader,
	action: action,
	element: <SubscriptionGenerateReport />,
};
