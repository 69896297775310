import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import { CalendarPlus, Eye, EyeOff, PlusCircle, Wand2 } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useActionData,
	useLoaderData,
	useParams,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import { ToastType } from 'ui/components/Toaster/Toast';
import { selectColumn } from 'utils/columns/genericColumns';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	RenewSubscriptionsResponse,
	SubscriptionItem,
} from '../../api/SubscriptionAPI';
import * as subscriptionColumns from '../../util/columns/subscriptionColumns';
import UpdateSubscriptionExpiryModal, {
	renewSubscriptionsSchema,
} from './UpdateSubscriptionExpiryModal';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const customerId = params.customerId as string;

	let searchParams = new URL(request.url).searchParams;
	searchParams.set('customerIds', [customerId].toString());

	const subscriptionsData =
		await SubscriptionAPI.getSubscriptions(searchParams);

	return {
		subscriptionsData: subscriptionsData,
	};
};

const action = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const formData = await request.formData();
	const action = formData.get('action') as string;

	if (action === 'updateExpiry') {
		const data = renewSubscriptionsSchema.parse(formDataAsObject(formData));

		const responseData = await SubscriptionAPI.renewSubscriptions(data);

		if (responseData instanceof Error) {
			createToast(ToastType.ERROR, 'Failed to renew subscriptions');
			return null;
		} else {
			return responseData;
		}
	}

	return null;
};

const CustomerSubscriptions = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const params = useParams();
	const customerId = params.customerId as string;

	const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
		{}
	);
	const [isHighlightModeActive, setIsHighlightModeActive] = useState(false);
	const [isExpiryModalOpen, setIsExpiryModalOpen] = useState(false);
	const [selectedSubscriptionIds, setSelectedSubscriptionIds] = useState<
		string[]
	>([]);

	const [responseData, setResponseData] = useState<
		RenewSubscriptionsResponse | undefined
	>();

	useEffect(() => {
		if (actionData && isExpiryModalOpen) {
			setResponseData(actionData);
		}
	}, [actionData]);

	const handleRowSelection = useCallback(
		(rowSelection: Record<string, boolean>) => {
			const selectedSubscriptionIds = Object.keys(rowSelection);

			setSelectedSubscriptionIds(selectedSubscriptionIds);
		},
		[setSelectedSubscriptionIds]
	);

	const renewableSubscriptionIds = useMemo(
		() =>
			data.subscriptionsData.subscriptions.items
				.filter(
					(subscription) =>
						new Date().getFullYear() === subscription.endPeriod.year &&
						!selectedSubscriptionIds.includes(subscription.id)
				)
				.map((subscription) => subscription.id),
		[data.subscriptionsData.subscriptions.items, selectedSubscriptionIds]
	);

	const handleAutoSelectRenewables = () => {
		setRowSelectionState((prev) => ({
			...prev,
			...renewableSubscriptionIds.reduce(
				(acc, id) => ({ ...acc, [id]: true }),
				{}
			),
		}));

		setSelectedSubscriptionIds(renewableSubscriptionIds);
	};

	const columns = [
		selectColumn as ColumnDef<SubscriptionItem, void>,
		subscriptionColumns.nameColumn,
		subscriptionColumns.statusColumn,
		subscriptionColumns.productNameColumn,
		subscriptionColumns.startPeriodColumn,
		subscriptionColumns.endPeriodColumn,
		subscriptionColumns.licensesColumn,
		subscriptionColumns.originsColumn,
		subscriptionColumns.destinationsColumn,
		subscriptionColumns.subscriptionUsersColumn,
	];

	return (
		<Flex direction="column" gap={16}>
			<Table
				identifierKey="id"
				columns={columns}
				data={data.subscriptionsData.subscriptions.items}
				onRowSelectionChange={handleRowSelection}
				tableOptions={{
					state: {
						rowSelection: rowSelectionState,
					},
					onRowSelectionChange: setRowSelectionState,
					enableRowSelection: (row) => {
						// Disable selection for rows where the date is last year or earlier
						if (row.original.endPeriod.year < new Date().getFullYear()) {
							return false;
						}

						return true;
					},
				}}
				highlightSelectedRows={isHighlightModeActive}
			/>

			<TabActions>
				{Object.values(rowSelectionState).some((o) => o) && (
					<Button
						variant="secondary"
						size="small"
						icon={isHighlightModeActive ? EyeOff : Eye}
						onClick={() => setIsHighlightModeActive(!isHighlightModeActive)}
					>
						{isHighlightModeActive ? 'Stop Highlighting' : 'Highlight Selected'}
					</Button>
				)}
				{renewableSubscriptionIds.length > 0 && (
					<Button
						variant="secondary"
						size="small"
						icon={Wand2}
						onClick={handleAutoSelectRenewables}
					>
						Select Expiring ({renewableSubscriptionIds.length})
					</Button>
				)}
				<Button
					variant="secondary"
					size="small"
					icon={CalendarPlus}
					disabled={selectedSubscriptionIds.length === 0}
					onClick={() => setIsExpiryModalOpen(true)}
				>
					Renew
					{selectedSubscriptionIds.length > 0
						? ` (${selectedSubscriptionIds.length}/${data.subscriptionsData.subscriptions.totalCount})`
						: ''}
				</Button>
				<Button
					variant="secondary"
					size="small"
					to={`/subscriptions/create?customerId=${customerId}`}
					icon={PlusCircle}
				>
					Add Subscription
				</Button>
			</TabActions>

			<UpdateSubscriptionExpiryModal
				isOpen={isExpiryModalOpen}
				onClose={() => {
					setIsExpiryModalOpen(false);
					setResponseData(undefined);
				}}
				subscriptionIds={selectedSubscriptionIds}
				customerId={customerId}
				responseData={responseData}
			/>

			{data.subscriptionsData.subscriptions.totalCount >
				data.subscriptionsData.subscriptions.pageSize && (
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.subscriptionsData.subscriptions.page}
					pageParameterName="page"
					pageSize={data.subscriptionsData.subscriptions.pageSize}
					itemCount={data.subscriptionsData.subscriptions.totalCount}
					canChangePageSize
				/>
			)}
		</Flex>
	);
};

export const CUSTOMER_DETAILS_SUBSCRIPTIONS_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <CustomerSubscriptions />,
};
