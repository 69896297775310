import { createColumnHelper } from '@tanstack/react-table';
import {
	AlertTriangle,
	ArrowLeftRight,
	ArrowRight,
	Pencil,
	Plus,
	Trash,
} from 'lucide-react';
import React from 'react';
import { AirlineList } from 'ui/components/AirlinePicker';
import Button from 'ui/components/Button/Button';
import CheckboxField from 'ui/components/CheckboxField';
import Flex from 'ui/components/Flex';
import { FreightForwarderList } from 'ui/components/FreightForwarderPicker';
import { LocationList } from 'ui/components/LocationPicker/LocationList';
import Pill from 'ui/components/Pill';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import Toggle from 'ui/components/Toggle/Toggle';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import TooltipContent from 'ui/components/Tooltip/TooltipContent';
import TooltipTrigger from 'ui/components/Tooltip/TooltipTrigger';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import ValidatedFormError from 'ui/components/ValidatedFormError';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment/YearAndMonthFragment';
import { AirlineSingleOption } from 'utils/api/AirlinesAPI';
import { LabeledValue } from 'utils/types/common';
import { flattenErrors } from 'utils/zod/zodErrors';
import {
	AllProductDetails,
	GetSubscriptionOptionsResponse,
	ProductOption,
} from '../../api/SubscriptionAPI';
import { DropdownField } from '../../forms/DropdownField';
import { MultiDropdownField } from '../../forms/MultiDropdownField';
import { ProductTypeCode } from '../../util/schemas/subscriptionSchema';
import {
	EditableWebToolDataGroup,
	WebToolSubscriptionGroupEditor,
	useWebToolSubscriptionEditor,
} from './WebToolConfigurationEditorProvider';

type WebToolConfigurationEditorProps = {
	product: ProductOption;
	productConfig?: Partial<AllProductDetails> & { type: ProductTypeCode };
	options: GetSubscriptionOptionsResponse;
	customerId?: string;
};

export const WebToolConfigurationEditor = ({
	product,
	productConfig,
	options,
	customerId,
}: WebToolConfigurationEditorProps) => {
	const productType = product?.productType;
	const { webtoolEditorData, dispatch } = useWebToolSubscriptionEditor();

	const [webToolDataSource, setWebToolDataSource] =
		React.useState<LabeledValue | null>(
			productConfig?.webToolDataSource ?? null
		);

	const [webToolDataSubscription, setWebToolDataSubscription] =
		React.useState<LabeledValue | null>(
			productConfig?.webToolDataSubscription ?? null
		);

	const productOptions = React.useMemo(() => {
		return options.webToolOptions[productType];
	}, [productType]);

	const webToolDataSources = React.useMemo(() => {
		if (!productOptions) return [];

		const options = productOptions.dataSourceOptions;

		if (
			webToolDataSource &&
			!options.find((o) => o.value === webToolDataSource.value)
		) {
			setWebToolDataSource(null);
		}

		return options;
	}, [productOptions, webToolDataSource, setWebToolDataSource]);

	const webToolDataSubscriptions = React.useMemo(() => {
		if (!productOptions) return [];

		if (!webToolDataSource) return [];

		const options =
			productOptions.dataSubscriptionOptions[webToolDataSource.value] ?? [];

		if (
			webToolDataSubscription &&
			!options.find((o) => o.value === webToolDataSubscription.value)
		) {
			setWebToolDataSubscription(null);
		}

		return options;
	}, [
		productOptions,
		webToolDataSource,
		webToolDataSubscription,
		setWebToolDataSubscription,
	]);

	return (
		<>
			<input
				name="productConfiguration.type"
				value={productType}
				type="hidden"
			/>

			{productType === 'ndd-contributor' && (
				<TextField
					minLength={4}
					maxLength={4}
					label="Contributor Code"
					name="productConfiguration.contributorCode"
					defaultValue={productConfig?.contributorCode}
					isRequired
				/>
			)}

			{productType === 'ndd-contributor' && (
				<TextField
					minLength={3}
					maxLength={75}
					label="Contributor Name"
					name="productConfiguration.contributorName"
					defaultValue={productConfig?.contributorName}
					isRequired
				/>
			)}

			{productType === 'ndd-contributor' && (
				<DropdownField
					label="Submission Status"
					name="productConfiguration.submissionStatus"
					isRequired
					identifierKey="value"
					contentSource={(data) => data.label}
					placeholder="Select"
					options={options.submissionStatusOptions}
					initialValue={productConfig?.submissionStatus}
				/>
			)}

			{productType !== 'ndd-contributor' &&
				productType !== 'capacity-web-tool' &&
				productType !== 'capacity-web-tool-iata' &&
				productType !== 'capacity-web-tool-admin' && (
					<>
						<DropdownField
							label="Data Source"
							name="productConfiguration.webToolDataSource"
							isRequired
							identifierKey="value"
							contentSource={(data) => data.label}
							placeholder="Select"
							options={webToolDataSources}
							selectedOption={webToolDataSource}
							onOptionSelected={(option) => {
								setWebToolDataSource(option);
								dispatch({
									type: 'set-data-source',
									payload: option?.value ?? undefined,
								});
							}}
						/>

						<DropdownField
							label="Data Subscription"
							name="productConfiguration.webToolDataSubscription"
							isRequired
							identifierKey="value"
							contentSource={(data) => data.label}
							placeholder="Select"
							options={webToolDataSubscriptions}
							selectedOption={webToolDataSubscription}
							onOptionSelected={setWebToolDataSubscription}
						/>
					</>
				)}

			<Flex justifyContent="space-between" alignItems="center">
				<Flex gap={8}>
					<Toggle
						isChecked={webtoolEditorData.mode === 'multiple'}
						onChange={() => dispatch({ type: 'toggle-mode' })}
					/>
					<span onClick={() => dispatch({ type: 'toggle-mode' })}>
						Use Multiple Groupings
					</span>
				</Flex>

				<Button
					size="small"
					icon={Plus}
					style={webtoolEditorData.mode === 'multiple' ? {} : { opacity: '0' }}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						dispatch({ type: 'create' });
					}}
				>
					Add
				</Button>
			</Flex>

			<ValidatedFormError name={'productConfiguration.webToolDataGroups'} />

			{webtoolEditorData.mode === 'multiple' ? (
				<>
					<Table
						identifierKey={'id'}
						data={webtoolEditorData.dataGroups}
						columns={[
							product.productType === 'web-tool-airline' && airlineColumn,
							product.productType === 'web-tool-freight-forwarder' &&
								freightForwarderColumn,
							product.productType === 'web-tool-freight-forwarder' &&
								webtoolEditorData.dataSource === 'ndd' &&
								nddContributorColumn,
							originDestinationColumn,
							dataPeriodColumn,
							editButtonColumn,
						]}
						emptyText="No groupings added yet"
					></Table>
				</>
			) : (
				<>
					<WebToolSubscriptionGroupEditor
						namePrefix="productConfiguration.webToolDataGroups.0"
						group={webtoolEditorData.dataGroups[0]}
						productType={product.productType}
						customerId={customerId}
						customerNddContributorOptions={
							options.customerNddContributorOptions
						}
						webToolDataSource={webToolDataSource?.value}
						dispatch={dispatch}
					/>
				</>
			)}

			{productType !== 'ndd-contributor' &&
				productType !== 'web-tool-iata' &&
				productType !== 'web-tool-admin' && (
					<MultiDropdownField<LabeledValue>
						label="Hidden Fields"
						name="productConfiguration.webToolHiddenFields"
						isGrouped={true}
						options={
							productType == 'capacity-web-tool' ||
							productType == 'capacity-web-tool-iata' ||
							productType == 'capacity-web-tool-admin'
								? options.capacityWebToolHiddenFields
								: options.webToolHiddenFields
						}
						identifierKey={'value'}
						contentSource={HiddenFieldSearchLayout}
						pillComponent={HiddenFieldPill}
						initialValues={productConfig?.webToolHiddenFields}
					/>
				)}

			{productOptions?.customerMarketBreakdown && (
				<CheckboxField
					name="productConfiguration.marketBreakdown"
					label="Market with Customer Breakdown"
					value="true"
					defaultChecked={productConfig?.marketBreakdown}
				/>
			)}
		</>
	);
};

// Hidden Field Dropdown Layout
function HiddenFieldSearchLayout(data: LabeledValue) {
	return <>{data.label}</>;
}

type HiddenFieldPillProps = {
	value: LabeledValue;
	onRemove?: () => void;
};
function HiddenFieldPill({ value, onRemove }: HiddenFieldPillProps) {
	return <Pill name={value.label} onRemove={onRemove} />;
}

// Table columns
const columnHelper = createColumnHelper<EditableWebToolDataGroup>();

function dataGroupInputName(name: string, index: number) {
	return `productConfiguration.webToolDataGroups.${index}.${name}`;
}

const airlineColumn = columnHelper.accessor('airlines', {
	header: 'Airlines',
	cell: (info) => {
		return (
			<>
				{info
					.getValue()
					?.filter(
						(airline): airline is AirlineSingleOption => 'code' in airline
					)
					.map((airline, index) => (
						<input
							type="hidden"
							key={airline.code}
							name={dataGroupInputName(`airlines.${index}`, info.row.index)}
							value={airline.code}
						/>
					))}
				<AirlineList
					airlines={info.getValue()}
					emptyText={'-'}
					variant="small"
				/>
			</>
		);
	},
});

const freightForwarderColumn = columnHelper.accessor('freightForwarders', {
	header: 'FreightForwarders',
	cell: (info) => {
		return (
			<>
				{info
					.getValue()
					?.map((freightForwarder, index) => (
						<input
							type="hidden"
							key={freightForwarder.code}
							name={dataGroupInputName(
								`freightForwarders.${index}`,
								info.row.index
							)}
							value={freightForwarder.freightForwarderId}
						/>
					))}
				<FreightForwarderList
					freightForwarders={info.getValue()}
					emptyText={'-'}
				/>
			</>
		);
	},
});

const nddContributorColumn = columnHelper.accessor('nddContributors', {
	header: 'NDD Contributors',
	cell: (info) => {
		const contributors = info.getValue();
		return (
			<>
				{contributors && contributors.length > 0
					? contributors.map((contributor, index) => (
							<>
								<input
									type="hidden"
									key={contributor.value}
									name={dataGroupInputName(
										`nddContributors.${index}`,
										info.row.index
									)}
									value={contributor.value}
								/>
								<Pill name={`${contributor.label}`} />
								&nbsp;
							</>
						))
					: '-'}
			</>
		);
	},
});

const originDestinationColumn = columnHelper.display({
	id: 'OriginDestination',
	header: 'Origin / Destination',
	cell: (info) => {
		return (
			<>
				<Flex gap={4}>
					<Flex gap={4}>
						<LocationList
							locations={info.row.original.origin}
							emptyText={'World'}
							variant="small"
						/>
					</Flex>
					<Flex>
						{info.row.original.includeReverseRoutes ? (
							<ArrowLeftRight size={14} />
						) : (
							<ArrowRight size={14} />
						)}
					</Flex>
					<Flex gap={4}>
						<LocationList
							variant="small"
							locations={info.row.original.destination}
							emptyText={'World'}
						/>
					</Flex>
				</Flex>

				{info.row.original.origin?.map((location, index) => (
					<input
						type="hidden"
						key={location.locationId}
						name={dataGroupInputName(`origin.${index}`, info.row.index)}
						value={location.locationId}
					/>
				))}
				<input
					type="hidden"
					name={dataGroupInputName('includeReverseRoutes', info.row.index)}
					value={info.row.original.includeReverseRoutes?.toString()}
				/>
				{info.row.original.destination?.map((location, index) => (
					<input
						type="hidden"
						key={location.locationId}
						name={dataGroupInputName(`destination.${index}`, info.row.index)}
						value={location.locationId}
					/>
				))}
			</>
		);
	},
});

const dataPeriodColumn = columnHelper.display({
	id: 'time-period',
	header: 'Period',
	cell: (info) => {
		const { startPeriod, endPeriod } = info.row.original;

		return (
			<>
				{startPeriod || endPeriod ? (
					<>
						<YearAndMonthFragment value={startPeriod} />
						{' - '}
						<YearAndMonthFragment value={endPeriod} />
					</>
				) : (
					'-'
				)}
				<input
					type="hidden"
					name={dataGroupInputName('startPeriod.month', info.row.index)}
					value={startPeriod?.month}
				/>
				<input
					type="hidden"
					name={dataGroupInputName('startPeriod.year', info.row.index)}
					value={startPeriod?.year}
				/>
				<input
					type="hidden"
					name={dataGroupInputName('endPeriod.month', info.row.index)}
					value={endPeriod?.month}
				/>
				<input
					type="hidden"
					name={dataGroupInputName('endPeriod.year', info.row.index)}
					value={endPeriod?.year}
				/>
			</>
		);
	},
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: function EditColumn(info) {
		const { dispatch } = useWebToolSubscriptionEditor();
		const { errorTree } = useValidation(
			`productConfiguration.webToolDataGroups.${info.row.index}`
		);
		const flattened = flattenErrors(errorTree);
		return (
			<Flex gap={8} alignItems="center" justifyContent="end">
				{flattened.length > 0 && (
					<Tooltip>
						<TooltipContent>
							{flattened.map((error) => (
								<div key={error}>{error}</div>
							))}
						</TooltipContent>
						<TooltipTrigger>
							<AlertTriangle color="red" />
						</TooltipTrigger>
					</Tooltip>
				)}
				<Button
					variant="secondary"
					icon={Pencil}
					size="small"
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						dispatch({ type: 'edit', payload: { id: info.getValue() } });
					}}
				/>
				<Button
					variant="secondary"
					size={'small'}
					icon={Trash}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						dispatch({ type: 'remove', payload: { id: info.getValue() } });
					}}
				/>
				<input
					type="hidden"
					name={dataGroupInputName('id', info.row.index)}
					value={info.getValue()}
				/>
			</Flex>
		);
	},
});
