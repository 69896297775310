import classNames from 'classnames';
import { ChevronLeft, ChevronRight, LucideIcon } from 'lucide-react';
import { PropsWithChildren, useEffect, useId, useRef, useState } from 'react';
import { useOutlet } from 'react-router-dom';
import TabComponent from './Tab';
import TabsContext from './TabsContext';

export type TabAction = {
	label: string;
	onExecute: () => void;
	isDestructive?: boolean;
};

export type Tab = {
	name: string;
	to: string;
	isCloseable?: boolean;
	actions?: TabAction[];
	end?: boolean;
	icon?: LucideIcon;
};

type TabsProps<T extends Tab> = PropsWithChildren<{
	tabs: T[];
	baseUrl?: string;
	className?: string;
	renderOutlet?: boolean;
	onTabClose?: (tab: T) => void;
	onTabActivate?: (tab: T) => void;
	emptyState?: React.ReactNode;
}>;

const Tabs = <T extends Tab>({
	baseUrl = '',
	tabs,
	children,
	className,
	renderOutlet = true,
	onTabClose,
	onTabActivate,
	emptyState,
}: TabsProps<T>) => {
	const actionsPortalId = useId();
	const [hasOverflowLeft, setHasOverflowLeft] = useState(false);
	const [hasOverflowRight, setHasOverflowRight] = useState(false);
	const outlet = useOutlet();

	const tabsItemsRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		handleScroll();
	}, [tabs]);

	const handleScroll = () => {
		const tabsItems = tabsItemsRef.current;
		if (!tabsItems) return;

		const { scrollLeft, scrollWidth, clientWidth } = tabsItems;

		const hasOverflowLeft = scrollLeft > 0;
		const hasOverflowRight = scrollLeft + clientWidth < scrollWidth;

		setHasOverflowLeft(hasOverflowLeft);
		setHasOverflowRight(hasOverflowRight);
	};

	const performScroll = (direction: 'left' | 'right') => {
		const tabsItems = tabsItemsRef.current;
		if (!tabsItems) return;

		const { scrollLeft, clientWidth } = tabsItems;

		const targetScrollAmount = 150;

		// Move 20px, or the remaining distance to the end of the list
		const scrollAmount =
			direction === 'right'
				? Math.min(targetScrollAmount, clientWidth - scrollLeft)
				: Math.max(-targetScrollAmount, -scrollLeft);

		tabsItems.scrollTo({
			left: scrollLeft + scrollAmount,
			behavior: 'smooth',
		});
	};

	return (
		<div className={classNames('tabs', className)}>
			<div className="tabs__header">
				<div className="tabs__overflow">
					<div
						role="button"
						className={classNames(
							'tabs__overflow-arrow',
							hasOverflowLeft && 'tabs__overflow-arrow--active'
						)}
						onClick={() => performScroll('left')}
					>
						<ChevronLeft />
					</div>

					<ul
						className="tabs__items"
						ref={tabsItemsRef}
						onScroll={handleScroll}
					>
						{tabs.map((tab, tabIndex) => (
							<TabComponent
								tab={tab}
								key={tabIndex}
								baseUrl={baseUrl}
								onActivate={() => onTabActivate?.(tab)}
								onClose={() => onTabClose?.(tab)}
								end={tab.end}
								icon={tab.icon}
							/>
						))}
					</ul>

					<div
						role="button"
						className={classNames(
							'tabs__overflow-arrow',
							hasOverflowRight && 'tabs__overflow-arrow--active'
						)}
						onClick={() => performScroll('right')}
					>
						<ChevronRight />
					</div>
				</div>

				<div className="tabs__actions" id={actionsPortalId}>
					{children}
				</div>
			</div>
			<TabsContext.Provider value={{ actionsPortalId }}>
				<div className="tabs__content">
					{renderOutlet && (outlet || emptyState)}
				</div>
			</TabsContext.Provider>
		</div>
	);
};

export default Tabs;
