import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { FloatingFocusManager, FloatingOverlay } from '@floating-ui/react';
import classNames from 'classnames';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	useDefaultFloating,
	useDefaultFloatingInteractions,
	useFloatingAutoScroll,
} from 'utils/hooks/floatingHooks';
import { DataSource, readByDataSource } from 'utils/types/dataSource';
import { IdentifierKeys } from 'utils/types/helpers';
import useFormReset from '../ValidatedForm/useFormReset';
import DropdownGroup from './DropdownGroup';
import DropdownGroupHeading from './DropdownGroupHeading';
import DropdownIndicators from './DropdownIndicators';
import DropdownInputs from './DropdownInputs';
import DropdownLoading from './DropdownLoading';
import DropdownOption from './DropdownOption';
import DropdownSearch from './DropdownSearch';
import { OptionGroup } from './types';

type SingleValue<T> = T | null;
type MultiValue<T> = T[];

export type DropdownSelectedOptions<T> =
	| {
			isMulti: true;
			selectedOptions: MultiValue<T>;
			onOptionsSelected: (value: MultiValue<T>) => void;
			previewSource: DataSource<T[], string | ReactNode>;
			onCopy?: (value: MultiValue<T>) => void;
	  }
	| {
			isMulti: false;
			selectedOptions: SingleValue<T>;
			onOptionsSelected: (value: SingleValue<T>) => void;
			previewSource: DataSource<T, string | ReactNode>;
			onCopy?: (value: SingleValue<T>) => void;
	  };

export type DropdownProps<T> = {
	identifierKey: IdentifierKeys<T>;
	typeaheadSource?: DataSource<T>;
	contentSource: DataSource<T, string | ReactNode>;

	optionDisabledSource?: DataSource<T, boolean>;
	hideDisabled?: boolean;

	name?: string;

	isGrouped?: boolean;
	options: OptionGroup<T>[] | T[];

	isOpen: boolean;
	onOpenChange: (open: boolean) => void;

	inputValue?: string;
	onInputChange?: (inputValue: string) => void;

	handlePasteSelection?: (value: string) => Promise<T | T[] | false>;
	handleSearchSubmitSelection?: (value: string) => Promise<T | T[] | false>;

	placeholder?: string;
	disableWhenEmpty?: boolean;

	isClearable?: boolean;
	isSideBySide?: boolean;
	isLoading?: boolean;
	isSearchable?: boolean;
	isDisabled?: boolean;

	buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
	dropdownProps?: React.HTMLAttributes<HTMLDivElement>;
} & DropdownSelectedOptions<T>;

const Dropdown = <T,>({
	selectedOptions,
	isMulti,
	name,
	options,
	isGrouped = false,
	onOptionsSelected,
	isSearchable = false,
	isOpen,
	inputValue,
	onInputChange,
	placeholder = 'Select...',
	disableWhenEmpty = true,
	onCopy,
	onOpenChange,
	handlePasteSelection,
	handleSearchSubmitSelection,
	identifierKey,
	contentSource,
	previewSource,
	optionDisabledSource,
	hideDisabled = false,
	typeaheadSource = (data) => data[identifierKey] as unknown as string,
	isClearable = true,
	isSideBySide = false,
	isLoading: propsIsLoading = false,
	isDisabled: propsIsDisabled = false,
	buttonProps,
	dropdownProps,
}: DropdownProps<T>) => {
	options = options || [];

	const [hasManuallyExpanded, setHasManuallyExpanded] = useState(false);

	useEffect(() => {
		setExpandedGroupIndices([]);
		setHasManuallyExpanded(false);
	}, [isOpen, options]);

	const [stateIsLoading, setStateIsLoading] = useState(false);
	const isLoading = propsIsLoading || stateIsLoading;

	const isOptionSelected = (option: T) => {
		if (isMulti) {
			return (selectedOptions as T[]).some(
				(selectedOption) =>
					selectedOption[identifierKey] === option[identifierKey]
			);
		} else {
			return (
				(selectedOptions as T | null)?.[identifierKey] === option[identifierKey]
			);
		}
	};

	// If the dropdown is multi-select, remove all values that are already selected
	const filteredOptions = useMemo(() => {
		let result = options;

		if (isMulti) {
			if (isGrouped) {
				result = (options as OptionGroup<T>[]).map((optionGroup) => ({
					...optionGroup,
					options: optionGroup.options.filter(
						(option) => !isOptionSelected(option)
					),
				}));
			} else {
				result = (options as T[]).filter((option) => !isOptionSelected(option));
			}
		}

		if (hideDisabled && optionDisabledSource) {
			if (isGrouped) {
				result = (result as OptionGroup<T>[]).map((optionGroup) => ({
					...optionGroup,
					options: optionGroup.options.filter(
						(option) => !readByDataSource(option, optionDisabledSource)
					),
				}));
			} else {
				result = (result as T[]).filter(
					(option) => !readByDataSource(option, optionDisabledSource)
				);
			}
		}

		return result;
	}, [selectedOptions, options, isMulti, isGrouped, hideDisabled]);

	// If the dropdown is multi-select, check if all options are selected
	const isExhausted =
		isMulti &&
		!isGrouped &&
		selectedOptions.length === options.length &&
		selectedOptions.length > 0;

	const isEmpty = filteredOptions.length === 0;
	const isEmptyDisabled = isEmpty && disableWhenEmpty && !isExhausted;

	const isDisabled = propsIsDisabled || isEmptyDisabled;

	const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
	const listContentRef = useRef(
		isGrouped
			? filteredOptions.flatMap((optionGroup) =>
					(optionGroup as OptionGroup<T>).options.map((option) =>
						readByDataSource(option, typeaheadSource)
					)
				)
			: filteredOptions.map((option) =>
					readByDataSource(option as T, typeaheadSource)
				)
	);

	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [expandedGroupIndices, setExpandedGroupIndices] = useState<number[]>([
		0,
	]);
	const [pointer, setPointer] = useState(false);

	const handleOpenChange = (open: boolean) => {
		if (open === true && isExhausted) return;
		onOpenChange(open);
	};

	useEffect(() => {
		if (!open && pointer) {
			setPointer(false);
		}
	});

	const { x, y, refs, strategy, context } = useDefaultFloating({
		open: isOpen,
		onOpenChange: handleOpenChange,
	});

	const isTyping = useRef(false);
	const { getReferenceProps, getFloatingProps, getItemProps } =
		useDefaultFloatingInteractions({
			context,
			setActiveIndex,
			activeIndex,
			listItemsRef,
			isOpen,
			contentRef: listContentRef,
			onTypingChange: (typing) => {
				isTyping.current = typing;
			},
		});

	useFloatingAutoScroll({
		listItemsRef,
		activeIndex,
		open: isOpen,
		pointer,
	});

	const handleClear = () => {
		setActiveIndex(null);

		if (isMulti) {
			onOptionsSelected([]);
		} else {
			onOptionsSelected(null);
		}
	};

	useFormReset(handleClear);

	const handleOptionsSelected = (options: T[]) => {
		const nonDisabledOptions = options.filter((option) => {
			const isDisabled =
				optionDisabledSource && readByDataSource(option, optionDisabledSource);
			return !isDisabled;
		});

		if (nonDisabledOptions.length === 0) return;

		if (isMulti) {
			onOptionsSelected(nonDisabledOptions);
		} else {
			onOptionsSelected(nonDisabledOptions[0]);
		}
	};

	const renderOption = (option: T) => {
		let index = 0;
		if (isGrouped) {
			index = (filteredOptions as OptionGroup<T>[])
				.flatMap((optionGroup) => optionGroup.options)
				.findIndex((o) => o === option);
		} else {
			index = filteredOptions.findIndex((o) => o === option);
		}

		let isSelected = false;
		if (isMulti) {
			isSelected = selectedOptions.includes(option);
		} else {
			isSelected = !!(
				selectedOptions &&
				selectedOptions[identifierKey] === option[identifierKey]
			);
		}

		return (
			<DropdownOption
				key={index}
				value={option}
				isSelected={isSelected}
				isActive={activeIndex === index}
				isDisabled={
					optionDisabledSource
						? readByDataSource(option, optionDisabledSource)
						: false
				}
				isTyping={isTyping.current ?? false}
				onSelect={(newValue) => handleOptionsSelected([newValue])}
				getProps={getItemProps}
				objRef={(node) => (listItemsRef.current[index] = node)}
			>
				{readByDataSource(option, contentSource)}
			</DropdownOption>
		);
	};

	const renderGroup = (optionGroup: OptionGroup<T>, index: number) => {
		const isExpanded = expandedGroupIndices.includes(index);

		const hasOptions = optionGroup.options.length > 0;
		const previousGroupsEmpty = (options as OptionGroup<T>[])
			.filter((_, idx) => idx < index)
			.every((group) => group.options.length === 0);

		if (
			hasOptions &&
			previousGroupsEmpty &&
			!isExpanded &&
			!hasManuallyExpanded
		) {
			setExpandedGroupIndices([...expandedGroupIndices, index]);
		}

		return (
			<DropdownGroup key={index}>
				<DropdownGroupHeading
					group={optionGroup}
					isExpanded={isExpanded}
					onExpandToggle={() => {
						setHasManuallyExpanded(true);

						if (isExpanded) {
							setExpandedGroupIndices(
								expandedGroupIndices.filter((i) => i !== index)
							);
						} else {
							setExpandedGroupIndices([...expandedGroupIndices, index]);
						}
					}}
				/>
				{isExpanded
					? optionGroup.options.map((option) => renderOption(option))
					: null}
			</DropdownGroup>
		);
	};

	const showPlaceholder = isMulti
		? selectedOptions.length === 0
		: selectedOptions === null;

	let placeholderValue: ReactNode | null = null;
	if (isEmptyDisabled) {
		placeholderValue = 'No options available';
	} else if (showPlaceholder) {
		placeholderValue = placeholder;
	} else {
		placeholderValue = isMulti
			? readByDataSource(selectedOptions, previewSource)
			: readByDataSource(selectedOptions as T, previewSource);
	}

	const showClearIndicator =
		isClearable &&
		(isMulti ? selectedOptions.length > 0 : selectedOptions !== null);

	const handlePaste = async (event: React.ClipboardEvent) => {
		if (handlePasteSelection) {
			event.preventDefault();
			onOpenChange(false);

			const pastedText = event.clipboardData.getData('text');

			setStateIsLoading(true);
			const newlySelectedOptions = await handlePasteSelection(pastedText);
			setStateIsLoading(false);

			if (newlySelectedOptions === false) {
				onInputChange?.(pastedText);
				onOpenChange(true);
				return;
			}

			const pastedOptions = Array.isArray(newlySelectedOptions)
				? newlySelectedOptions
				: [newlySelectedOptions];

			const filteredPastedOptions = isMulti
				? pastedOptions.filter((option) => !isOptionSelected(option))
				: pastedOptions;

			handleOptionsSelected(filteredPastedOptions);
		}
	};

	const handleSearch = async (value: string) => {
		if (handleSearchSubmitSelection) {
			const newlySelectedOptions = await handleSearchSubmitSelection(value);
			if (newlySelectedOptions === false) return;

			handleOptionsSelected(
				Array.isArray(newlySelectedOptions)
					? newlySelectedOptions
					: [newlySelectedOptions]
			);
		}
	};

	return (
		<div
			className={classNames('dropdown', isDisabled && 'dropdown--disabled')}
			aria-disabled={isDisabled}
			onPasteCapture={(e) => {
				handlePaste(e).catch(catchSilently);
			}}
		>
			<button
				{...getReferenceProps({
					ref: refs.setReference,
					type: 'button',
					className: 'dropdown__button',
					onKeyDown(event) {
						if (isClearable && event.key === 'Backspace') {
							handleClear();
						}
					},
				})}
				disabled={isDisabled}
				{...buttonProps}
			>
				<div className="dropdown__preview" data-cy={`dropdown.${name}`}>
					{placeholderValue}
				</div>
				<DropdownIndicators
					isLoading={isLoading}
					clearEnabled={showClearIndicator}
					openEnabled={!isExhausted}
					copyEnabled={
						!!onCopy &&
						(isMulti ? selectedOptions.length > 0 : selectedOptions !== null)
					}
					onCopyClick={() => {
						// Need to do this because of the way onCopy is typed
						if (isMulti) {
							onCopy?.(selectedOptions);
						} else {
							onCopy?.(selectedOptions);
						}
					}}
					onClearClick={() => {
						handleClear();
						handleOpenChange(false);
					}}
				/>
			</button>

			{/* @ts-ignore */}
			<DropdownInputs
				name={name}
				isMulti={isMulti}
				selectedOptions={selectedOptions}
				valueKey={identifierKey}
			/>

			{isOpen && (
				<FloatingOverlay lockScroll style={{ zIndex: 1000 }}>
					<FloatingFocusManager context={context}>
						<div
							className="dropdown__content"
							{...getFloatingProps({
								ref: refs.setFloating,
								style: {
									position: strategy,
									top: y ?? 0,
									left: x ?? 0,
									overflow: 'auto',
								},
								...dropdownProps,
								onPointerMove() {
									setPointer(true);
								},
								onKeyDown(event) {
									setPointer(false);

									if (event.key === 'Tab') {
										handleOpenChange(false);
									}
								},
							})}
						>
							{isSearchable && (
								<DropdownSearch
									value={inputValue ?? ''}
									onChange={(e) => {
										if (onInputChange) {
											onInputChange(e.target.value);
										}
									}}
									onSubmit={(e) => {
										handleSearch(e).catch(catchSilently);
									}}
								/>
							)}
							<ul
								className={classNames(
									'dropdown__list',
									isGrouped && 'dropdown__list--grouped',
									isSideBySide && 'dropdown__list--side-by-side'
								)}
							>
								{!isLoading &&
									(isGrouped
										? (filteredOptions as OptionGroup<T>[]).map(
												(optionGroup, index) => renderGroup(optionGroup, index)
											)
										: (filteredOptions as T[]).map((option, index) =>
												renderOption(option)
											))}
								{isLoading && <DropdownLoading />}
							</ul>
						</div>
					</FloatingFocusManager>
				</FloatingOverlay>
			)}
		</div>
	);
};

export default Dropdown;
